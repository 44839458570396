import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Createdigitalcard from "./pages/Createdigitalcard";
import CorporateBlue from "./pages/corporateblue/CorporateBlue";
import ClassyPurple from "./pages/classypurple/ClassyPurple";
import WhiteProfessional from "./pages/whiteprofessional/WhiteProfessional";
import GlossBlack from "./pages/glossblack/GlossBlack";
import CurvyOrange from "./pages/curvyorange/CurvyOrange";
import React, { useEffect, useState } from "react";
import Page404 from "./pages/Phe404";
import UserProfile from "./pages/UserProfile";
import UserProfileUpdate from "./pages/UserProfileUpdate";
import UserProfileCardManagement from "./pages/UserProfileCardManagement";
import UserProfileFeedbacks from "./pages/UserProfileFeedbacks";
import Cookies from "js-cookie";
import UserProfileContact from "./pages/UserProfileContact";
import Pricing from "./pages/Pricing";
import DynamicCard from "./pages/DynamicCard";
import Dashboard from "./admin/Dashboard";
import Registrations from "./admin/Registrations";
import ForgotPassword from "./pages/ForgotPassword";
import Enquiries from "./admin/Enquiries";
import Orders from "./admin/Orders";
import AdminLogin from "./admin/AdminLogin";
import UserProfileOrders from "./pages/UserProfileOrders";
import Themes from "./admin/Themes";
import ContactUs from "./pages/ContactUs";
import Newsletter from "./admin/Newsletter";
import UpdateDigitalcard from "./pages/UpdateDigitalCard";
import CorporateBlueStatic from "./staticpages/CorporateBlueStatic";
import CurvyOrangeStatic from "./staticpages/CurvyOrangeStatic";
import PreviewDynamicCard from "./pages/PreviewDynamicCard";
import GlossBlackStatic from "./staticpages/GlossBlackStatic";
import WhiteProfessionalStatic from "./staticpages/WhiteProfessionalStatic";
import ClassyPurpleStatic from "./staticpages/ClassyPurpleStatic";
const App = () => {
  const [data, setData] = useState({
      "_id": 1,
      "Name": "John Doe2",
      "Designation": "CEO",
      "CompanyName": "John Associates",
      "FacebookLink": "#",
      "TwitterLink": "#",
      "IndtagramLink": "#",
      "LinkedinLink": "#",
      "Whatsapp": "#",
      "PhoneCode": "+91",
      "PhoneNumber": "9830565580",
      "Email": "johndoe@example.com",
      "Website": "example.com",
      "Address": "33, Townshend Road, Purnashish Building, Ground Floor, Kolkata -700025 West Bengal, India",
      "isPreview": false
  });
  // let globalUserName = "";
  // if (Cookies.get("user")) {
  //   globalUserName = JSON.parse(Cookies.get("user")).fname + "-" + JSON.parse(Cookies.get("user")).lname;
  // }
  const handlePreview = (newObject) => {
    setData(newObject);
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/signup" element={<Signup />}/>
        <Route path="/create-card" element={<AuthProtected><Createdigitalcard previewHandler={handlePreview}/></AuthProtected>}/>
        <Route path="/contact-us" element={<ContactUs />}/>
        <Route path="/user-profile" element={<AuthProtected><UserProfile /></AuthProtected>}/>
        <Route path="/user-profile-update" element={<AuthProtected><UserProfileUpdate /></AuthProtected>}/>
        <Route path="/user-profile-card-management" element={<AuthProtected><UserProfileCardManagement /></AuthProtected>}/>
        <Route path="/user-profile-feedback" element={<AuthProtected><UserProfileFeedbacks /></AuthProtected>}/>
        <Route path="/user-profile-contact" element={<AuthProtected><UserProfileContact /></AuthProtected>}/>
        <Route path="/user-profile-orders" element={<AuthProtected><UserProfileOrders /></AuthProtected>}/>
        <Route path="/pricing" element={<Pricing />}/>
        <Route path="forgot-password" element={<ForgotPassword />}/>
        {/* Card Routes */}
        <Route path="/card/:id" element={<DynamicCard />}/>
        <Route path="/preview-card/:id" element={<PreviewDynamicCard />}/>
        <Route path="/preview/corporate-blue" element={<CorporateBlue info={ data }/>}/>
        <Route path="/preview/gloss-black" element={<GlossBlack info={ data }/>}/>
        <Route path="/preview/curvy-orange" element={<CurvyOrange info={ data }/>}/>
        <Route path="/preview/white-professional" element={<WhiteProfessional info={ data }/>}/>
        <Route path="/preview/classy-purple" element={<ClassyPurple info={ data }/>}/>
        <Route path="/update-card/:id" element={<UpdateDigitalcard />}/>

        {/* static pages */}
        <Route path="/static/corporate-blue" element={<CorporateBlueStatic />}/>
        <Route path="/static/curvy-orange" element={<CurvyOrangeStatic />}/>
        <Route path="/static/gloss-black" element={<GlossBlackStatic />}/>
        <Route path="/static/white-professional" element={<WhiteProfessionalStatic />}/>
        <Route path="/static/classy-purple" element={<ClassyPurpleStatic />}/>

        {/* Error Route */}
        <Route path="*" element={<Page404 />}/>
        {/* Admin Routes */}
        <Route path="/admin/dashboard" element={<AdminAuthProtected><Dashboard /></AdminAuthProtected>}/>
        <Route path="/admin/registrations" element={<AdminAuthProtected><Registrations /></AdminAuthProtected>}/>
        <Route path="/admin/enquiries" element={<AdminAuthProtected><Enquiries /></AdminAuthProtected>}/>
        <Route path="/admin/orders" element={<AdminAuthProtected><Orders /></AdminAuthProtected>}/>
        <Route path="/admin/themes" element={<AdminAuthProtected><Themes /></AdminAuthProtected>}/>
        <Route path="/admin/newsletter" element={<AdminAuthProtected><Newsletter /></AdminAuthProtected>}/>
        <Route path="/admin/login" element={<AdminLogin />}/>
      </Routes>
    </BrowserRouter>
  )
}

const AuthProtected = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!Cookies.get("user")) {
          navigate("/login");
        }
      }, [navigate]);
    
      if (Cookies.get("user")) {
        return children;
      } else {
        return null;
      }
}
const AdminAuthProtected = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
      if (!Cookies.get("admin")) {
        navigate("/admin/login");
      }
    }, [navigate]);
  
    if (Cookies.get("admin")) {
      return children;
    } else {
      return null;
    }
}

export default App;