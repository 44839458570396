import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { backendUrl } from '../data/Url';
const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [showEmailError, setShowEmailError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    useEffect(() => {
        import("./Login.css");
    }, [])
    const navigate = useNavigate();
    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            var valid = true;
            if (email.length == 0) {
                setShowEmailError(true);
                valid = false;
            } else {
                setShowEmailError(false);
            }
            if (password.length == 0) {
                setShowPasswordError(true);
                valid = false;
            } else {
                setShowPasswordError(false);
            }
            if (valid) {
                const res = await axios.post(`${backendUrl}/validate-user-login`, {
                    Email: email,
                    Password: password
                });
                var toastOptions = {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate("/")
                }
                var toastOptions2 = {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                }
                
                const dt = res.data;
                const status = dt.Status;
                const user = {
                    "userid": dt.UserID,
                    "fname": dt.FirstName,
                    "lname": dt.LastName,
                    "phone_code": dt.PhoneCode,
                    "phone": dt.Phone,
                    "email": dt.Email,
                    "company": dt.Company,
                    "address": dt.Address,
                    "country": dt.Country,
                    "state": dt.State,
                    "city": dt.City,
                    "pin": dt.PinCode
                }
                if (status === 200) {
                    toast.success(dt.Message, toastOptions);
                    //localStorage.setItem("user", JSON.stringify(user));
                    Cookies.set("user", JSON.stringify(user), { expires: 10 });
                } else if (status === 400) {
                    toast.warning(dt.Message, toastOptions2);
                } else if (status === 500) {
                    toast.error(dt.Message, toastOptions2);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        $(document).on("click", function (e) {
            if (e.which == 12) {
                alert("Enter");
                handleLogin(e);
            }
        })
    }, [])

    return (
        <div className='form-wrapper-layout'>
            <div className="row m-0">
                <div className="col-md-8 p-0">
                    <div className="login-leftbox">
                        <img src="assets/images/loginpge-image.webp" alt=""/>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="login-rightbox">
                        <div style={{width: "100%"}}>
                            <form onSubmit={handleLogin}>
                                <h1 className='mb-4'>Login</h1>
                                <div className="mb-2">
                                    <input id="email" placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)}/>
                                    {showEmailError && <p className='text-danger mb-0'>Email Required!</p>}
                                </div>
                                <div className="mb-2">
                                    <input id="password" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}/>
                                    {showPasswordError && <p className='text-danger mb-0'>Password Required!</p>}
                                </div>
                                <button className="btn btn-primary btn-main w-100 mb-3" type="submit">Login</button>
                            </form>
                            <p><Link to="/forgot-password" className='form-link'>Forgot Password ?</Link></p>
                            <p>Not have an account, <Link to="/signup" className='form-link'>Create Now</Link></p>
                            {/* <div className='h6'>Or else, Login using</div> */}
                            {/* <ul className='social-login mt-3'>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-facebook'></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className="fa-brands fa-x-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-instagram'></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-linkedin' ></i></Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;