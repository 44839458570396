import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Editor = ({ value, onChange, height }) => {
    return (
        <div>
        <ReactQuill value={value} onChange={onChange} style={{ height: height, overflow: "hidden" }}/>
        </div>
    )
}

export default Editor;