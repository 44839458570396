import { useNavigate, useParams } from "react-router-dom";
import DigitalCardLayout from "../components/DigitalCardLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Page404 from "./Phe404";
import { backendUrl, imageUrl } from "../data/Url";
const PreviewDynamicCard = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [filteredCardData, setFilteredCardData] = useState({});
    const [controls, setControls] = useState(true);
    const [error404, setError404] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/card-preview/${id}`);
                console.log(res.data.data1);
                setFilteredCardData(res.data.data);
                
                if (Cookies.get("user")) {
                    if (JSON.parse(Cookies.get("user")).userid !== res.data.data1.UserID) {
                        setError404(true);
                    }
                } else if (!Cookies.get("user")) {
                    setError404(true);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, []);
    useEffect(() => {
        if (filteredCardData && filteredCardData.ThemeCss && !error404) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${imageUrl}${filteredCardData.ThemeCss}`;
            link.onload = () => console.log(`CSS file loaded`);
            document.head.appendChild(link);

            import("./Preview.css");

            return () => {
                document.head.removeChild(link);
            }
        }
    }, [filteredCardData])
    const [menuShow, setMenuShow] = useState(false);
    const menuToogler = () => {
        setMenuShow(!menuShow);
    }
    const handlePublish2 = async () => {
        try {
            const res = await axios.post(`${backendUrl}/publish-card`, {
                cid: id,
                isPublished: true
            });
            const status = res.data.Status;
            if (status === 200) {
                toast.success("Your card is successfully published, wait we are redirecting", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate(`/card/${id}`)
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
        {
            error404 ?
            <Page404 />
            :
            <>
            {
                        controls &&
                        <>
                        <ul className={`fixed-bottom-nav ${menuShow ? "show" : ""}`}>
                            <li className='open-close'>
                                <button onClick={() => menuToogler()}><i className='bx bx-left-arrow-alt'></i></button>
                            </li>
                            <li>
                                <Link to="/user-profile-card-management" className='btn-publish'>
                                <i className='bx bxs-id-card' ></i> Card Management
                                </Link>
                            </li>
                            <li>
                                <Link to={`/update-card/${id}`} className='btn-publish'>
                                    <i className='bx bx-edit'></i> Edit
                                </Link>
                            </li>
                            <li>
                                <button className='btn-publish' onClick={() => handlePublish2()}>
                                    <i className='bx bx-upload'></i> PUBLISH
                                </button>
                            </li>
                        </ul>
                        </>
                    }
                    <DigitalCardLayout>
                        {filteredCardData && (
                            <div dangerouslySetInnerHTML={{ __html: filteredCardData.html }}></div>
                        )}
                    </DigitalCardLayout>
            </>
        }
        </>
    )
}

export default PreviewDynamicCard;