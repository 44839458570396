import { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const AdminLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const adminUser = {
        "Email": "exim@planetexim.net",
        "Password": "Asdfrom3"
    }
    const handleAdminLogin = () => {
        //localStorage.setItem("admin", JSON.stringify(adminUser));
        if (email === adminUser.Email && password === adminUser.Password) {
            Cookies.set("admin", JSON.stringify(adminUser), { expires: 10 });
            navigate("/admin/dashboard");
        }
    }
    return (
        <div className="min-vh-100 d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleAdminLogin}>
                                    <h1 className="h3 mb-4">Admin Login</h1>
                                    <div className="mb-2">
                                        <input type="text" placeholder="Email" className="form-control" onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="mb-2">
                                        <input type="password" placeholder="Password" className="form-control" onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                    <button className="btn btn-primary w-100" type="submit">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin;