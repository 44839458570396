import { useEffect, useState } from 'react';
import DigitalCardLayout from '../../components/DigitalCardLayout';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { backendUrl, imageUrl } from '../../data/Url';
import PreviewLoader from '../PreviewLoader';
const CurvyOrange = ({ info }) => {

    const [loader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 900);
        window.scrollTo(0, 0);
        
    }, []);

    const [data, setData] = useState(info);

    const [cardName, setCardName] = useState(data.CardName);
    const [companyLogo, setCompanyLogo] = useState(data.CompanyLogo);
    const [profilePicture, setProfilePicture] = useState(data.ProfilePicture);
    const [name, setName] = useState(data.Name);
    const [designation, setDesignation] = useState(data.Designation);
    const [companyName, setCompaanyName] = useState(data.CompanyName);
    const [facebookLink, setFacebookLink] = useState(data.FacebookLink);
    const [twitterLink, setTwitterLink] = useState(data.TwitterLink);
    const [instagramLink, setIndtagramLink] = useState(data.InstagramLink);
    const [linkedinLink, setLinkedinLink] = useState(data.LinkedinLink);
    const [whatsappNumber, setWhatsappNumber] = useState(data.WhatsappNumber);
    const [phoneCode, setPhoneCode] = useState(data.PhoneCode);
    const [phone, setPhone] = useState(data.PhoneNumber);
    const [email, setEmail] = useState(data.Email);
    const [website, setWebsite] = useState(data.Website);
    const [address, setAddress] = useState(data.Address);

    const [userId, setUserId] = useState(JSON.parse(Cookies.get("user")).userid);
    const [theme, setTheme] = useState(16);
    const [allowSeo, setAllowSeo] = useState(data.AllowSeo);

    const [isPreview, setIsPreview] = useState(data.isPreview);
    const [isSaved, setIsSaved] = useState(false);
    const [isPublished, setIsPublished] = useState(data.isPublished);

    const [cardId, setCardId] = useState(0);

    const navigate = useNavigate();
    useEffect(() => {
        let link = document.querySelector('link[data-theme-css]');
        
        if (!link) {
            link = document.createElement("link");
            link.rel = "stylesheet";
            link.dataset.themeCss = true;
            document.head.appendChild(link);
        }

        const fetchCss = async () => {
            try {
                const res = await axios.get(`${backendUrl}/fetch-css/16`);
                link.href = `${imageUrl}${res.data.ThemeCss}`;
            } catch (error) {
                console.error('Error fetching CSS:', error);
            }
        };

        fetchCss();

        import("../Preview.css")

        return () => {
        };
    }, []);

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append("CardName", data.CardName);
            formData.append("CompanyLogo", data.CompanyLogo);
            formData.append("ProfilePicture", data.ProfilePicture);
            formData.append("Name", data.Name);
            formData.append("Designation", data.Designation);
            formData.append("CompanyName", data.CompanyName);
            formData.append("FacebookLink", data.FacebookLink);
            formData.append("TwitterLink", data.TwitterLink);
            formData.append("InstagramLink", data.InstagramLink);
            formData.append("LinkedinLink", data.LinkedinLink);
            formData.append("WhatsappNumber", data.WhatsappNumber);
            formData.append("PhoneCode", data.PhoneCode);
            formData.append("PhoneNumber", data.PhoneNumber);
            formData.append("Email", data.Email);
            formData.append("Website", data.Website);
            formData.append("Address", data.Address);
            formData.append("UserID", userId);
            formData.append("Theame", theme);
            formData.append("AllowSeo", data.AllowSeo);
            formData.append("isSaved", true);
            formData.append("isPublished", false);
            const res = await axios.post(`${backendUrl}/save-card`, formData, {
                'Content-Type': 'multipart/form-data'
            });
            const status = res.data.Status;
            if (status === 200) {
                setIsSaved(true);
                localStorage.removeItem("newcard");
                setCardId(res.data.CardID);
                toast.success("Your Information is saved successfully!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: navigate(`/preview-card/${res.data.CardID}`)
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handlePublish = async () => {
        try {
            setLoaderShow(true);
            const formData = new FormData();
            formData.append("CardName", data.CardName);
            formData.append("CompanyLogo", data.CompanyLogo);
            formData.append("ProfilePicture", data.ProfilePicture);
            formData.append("Name", data.Name);
            formData.append("Designation", data.Designation);
            formData.append("CompanyName", data.CompanyName);
            formData.append("FacebookLink", data.FacebookLink);
            formData.append("TwitterLink", data.TwitterLink);
            formData.append("InstagramLink", data.InstagramLink);
            formData.append("LinkedinLink", data.LinkedinLink);
            formData.append("WhatsappNumber", data.WhatsappNumber);
            formData.append("PhoneCode", data.PhoneCode);
            formData.append("PhoneNumber", data.PhoneNumber);
            formData.append("Email", data.Email);
            formData.append("Website", data.Website);
            formData.append("Address", data.Address);
            formData.append("UserID", userId);
            formData.append("Theame", theme);
            formData.append("AllowSeo", data.AllowSeo);
            formData.append("isSaved", true);
            formData.append("isPublished", true);
            const res = await axios.post(`${backendUrl}/save-card`, formData, {
                'Content-Type': 'multipart/form-data'
            });
            const status = res.data.Status;
            if (status === 200) {
                localStorage.removeItem("newcard");
                setLoaderShow(false);
                toast.success(res.data.Message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate(`/card/${res.data.CardID}`)
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [loaderShow, setLoaderShow] = useState(false);
    const handlePublish2 = async () => {
        try {
            setLoaderShow(true);
            const res = await axios.post(`${backendUrl}/publish-card`, {
                cid: cardId,
                isPublished: true
            });
            const status = res.data.Status;
            if (status === 200) {
                localStorage.removeItem("newcard");
                setLoaderShow(false);
                toast.success("Your card is successfully published, wait we are redirecting", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate(`/card/${cardId}`)
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [menuShow, setMenuShow] = useState(false);
    const menuToogler = () => {
        setMenuShow(!menuShow);
    }
    
    return (
        <DigitalCardLayout>
            {
                data.isPreview && 
                <>
                    <Link to="/create-card" className='back-btn'><i className='bx bx-arrow-back'></i></Link>
                    <ul className={`fixed-bottom-nav ${menuShow ? "show" : ""}`}>
                        <li className='open-close'>
                            <button onClick={() => menuToogler()}><i className='bx bx-left-arrow-alt'></i></button>
                        </li>
                        {
                            !isSaved &&
                        <li>
                            <Link to="/create-card" className='btn-publish'>
                                <i className='bx bx-arrow-back'></i> Go Back
                            </Link>
                        </li>
                        }
                        {
                            isSaved &&
                        <li>
                            <Link to="/update-card" className='btn-publish'>
                                <i className='bx bx-edit'></i> Edit
                            </Link>
                        </li>
                        }
                        {
                            !isSaved &&                      
                        <li>
                            <button className='btn-publish' onClick={() => handleSave()}>
                                <i className='bx bx-save' ></i> SAVE INFORMATION
                            </button>
                        </li>
                        }
                        {
                            !isSaved &&
                        <li>
                            <button className='btn-publish' type='button' data-bs-toggle="modal" data-bs-target="#warningModal">
                                <i className='bx bx-upload'></i> SAVE & PUBLISH
                            </button>
                        </li>
                        }
                        {
                            isSaved &&
                        <li>
                            <button className='btn-publish' onClick={() => handlePublish2()}>
                                <i className='bx bx-upload'></i> PUBLISH
                            </button>
                        </li>
                        }
                    </ul>
                    <div className={`loader ${loaderShow ? "show" : ""}`}>
                        <img src="assets/images/loader.gif" alt='loader'/>
                    </div>
                    <div class="modal fade" id="warningModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Save & Publish</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    Your information was not saved. Are you sure to save & publish directly ?
                                </div>
                                <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                                    <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" onClick={() => handlePublish()} class="btn btn-primary" style={{ width: "50%" }} data-bs-dismiss="modal">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                isPreview ?
                <>
                <PreviewLoader handler={loader}/>
                <div class="box-divider" id="basic">
                <div class="card-wrapper shadow">
                    <div class="card-head">
                        <div class="profile-image">
                            <img src={data.ProfilePicture || "assets/images/johndoe.jpg"} alt="profilepic" />
                        </div>
                        <div class="card-head-logo">
                            <img src={data.CompanyLogo || "assets/images/comp.png"} alt="logo" />
                        </div>
                    </div>
                    <div class="pt-3 p-15px">
                        <h1 class="h3 profil-name mb-0">{data.Name}</h1>
                        <p class="m-0">{data.Designation}</p>
                        <p class="mb-0">{data.CompanyName}</p>
                        <div class="social-border d-flex mt-3">
                            <ul class="social_box">
                                <li>
                                    <Link to={data.FacebookLink} target="_blank"><i class='bx bxl-facebook'></i></Link>
                                </li>
                                <li>
                                    <Link to={data.TwitterLink} target="_blank"><i class='bx bxl-twitter'></i></Link>
                                </li>
                                <li>
                                    <Link to={data.InstagramLink} target="_blank"><i class='bx bxl-instagram'></i></Link>
                                </li>
                                <li>
                                    <Link to={data.LinkedinLink} target="_blank"><i class='bx bxl-linkedin'></i></Link>
                                </li>
                                <li>
                                    <Link to={data.WhatsappNumber} target="_blank"><i class='bx bxl-whatsapp'></i></Link>
                                </li>
                            </ul>
                        </div>
                        <ul class="contact_box mt-3">
                            <li>
                                <div class="d-flex">
                                    <div class="contact_icon">
                                        <i class='bx bxs-phone'></i>
                                    </div>
                                    <div class="contact_content">
                                        <Link to={`tel:${data.PhoneNumber}`}>{data.PhoneCode} {data.PhoneNumber}</Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex">
                                    <div class="contact_icon">
                                        <i class='bx bxs-envelope'></i>
                                    </div>
                                    <div class="contact_content">
                                        <Link to={`mailto:${data.Email}`}>{data.Email}</Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex">
                                    <div class="contact_icon">
                                        <i class='bx bx-globe'></i>
                                    </div>
                                    <div class="contact_content">
                                        <Link to={data.Website} target="_blank">{data.Website}</Link>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex">
                                    <div class="contact_icon">
                                        <i class='bx bxs-map'></i>
                                    </div>
                                    <div class="contact_content">
                                        <Link to="#" target="_blank">
                                            {data.Address}
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="about">
                <div class="card-wrapper card-border shadow">
                    <div class="p-15px paragraph-14px">
                        <h2 class="h4">About</h2>
                        <div dangerouslySetInnerHTML={{ __html: data.About }}></div>
                    </div>
                </div>
            </div>
                </>
            : <Navigate to="/create-card"/>
            }
            {/* <div class="box-divider" id="about">
                <div class="card-wrapper card-border shadow">
                    <div class="p-15px paragraph-14px">
                        <h2 class="h4">About</h2>
                        <div dangerouslySetInnerHTML={{ __html: data.about }}></div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="shop">
                <div class="card-wrapper card-border shadow">
                    <div class="p-15px">
                        <h2 class="h4">Shop Online</h2>
                        <div className="row">
                            {data.shop_online.map((el, index) => {
                                return (
                                    <div class="col-6" key={el.product_id}>
                                        <div class="product_box">
                                            <div class="product-image">
                                                <img src="assets/images/02.jpg" alt="product" />
                                            </div>
                                            <div class="product-content text-center">
                                                <h3 class="h6">{el.product_name}</h3>
                                                <span class="text-muted">₹ {el.product_price}</span>
                                            </div>
                                            <button class="btn btn-primary w-100">Buy</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="products">
                <div class="card-wrapper card-border shadow">
                    <div class="p-15px">
                        <h2 class="h4">Products & Services</h2>
                        <div className="row">
                            {
                                data.products_services.map((el, index) => {
                                    return (
                                        <div class="col-12" key={el.product_sevice_id}>
                                            <div class="product_box">
                                                <div class="product-image">
                                                    <img src="assets/images/02.jpg" alt="product" />
                                                </div>
                                                <div class="product-content text-center">
                                                    <h3 class="h6">{el.product_service_name}</h3>
                                                </div>
                                                <button class="btn btn-primary w-100">Enquiry</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="gallery">
                <div class="card-wrapper shadow card-border">
                    <div class="p-15px">
                        <h2 class="h4">Gallery</h2>
                        <div class="row">
                            <div class="col-12">
                                <div class="product_box">
                                    <div class="product-image">
                                        <img src="assets/images/02.jpg" alt="product" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="product_box">
                                    <div class="product-image">
                                        <img src="assets/images/02.jpg" alt="product" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="payment">
                <div class="card-wrapper shadow card-border">
                    <div class="p-15px">
                        <h2 class="h4">Payment Info</h2>
                        <div class="h6">Bank Account Details:-</div>
                        <div class="table-responsive">
                            <table class="table bankdetails">
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>:</td>
                                        <td>{data.bank_details.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Account No.</td>
                                        <td>:</td>
                                        <td>{data.bank_details.account_no}</td>
                                    </tr>
                                    <tr>
                                        <td>IFSC Code</td>
                                        <td>:</td>
                                        <td>{data.bank_details.ifsc_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Bank Name</td>
                                        <td>:</td>
                                        <td>{data.bank_details.bank_name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="qr-code">
                            <img src="assets/images/qr.webp" alt="qr" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="feedback">
                <div class="card-wrapper shadow card-border">
                    <div class="p-15px">
                        <h2 class="h4">Feedback</h2>
                        <p class="mb-0">Select Star</p>
                        <div class="containet d-flex">
                            <div class="rating">
                                <input type="radio" name="clr" style={{ "--c": "#ff9933" }} />
                                <input type="radio" name="clr" style={{ "--c": "#ff9933" }} />
                                <input type="radio" name="clr" style={{ "--c": "#ff9933" }} />
                                <input type="radio" name="clr" style={{ "--c": "#ff9933" }} />
                                <input type="radio" name="clr" style={{ "--c": "#ff9933" }} />
                            </div>
                        </div>
                        <div class="form-group mt-4 mb-3">
                            <div class="mb-2">
                                <input type="text" id="yourname" placeholder="Your Name" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <input type="email" id="emailid" placeholder="Email ID" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <input type="tel" id="phonenumber" placeholder="Your Contact" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <textarea id="feedback" placeholder="Your Feedback" class="form-control"></textarea>
                            </div>
                            <button class="btn btn-primary btn_card w-100">Submit</button>
                        </div>
                        <p class="fsize-14">Note: for privecy and security reasons we do not show your contact details. For more info you can contact admin or your franchisee.</p>
                        <h6 class="text-primary mb-4">Latest feedback</h6>
                        <div class="feedbackbox">
                            <ul class="feeback-rating">
                                <li><i class="fa-solid fa-star"></i></li>
                                <li><i class="fa-solid fa-star"></i></li>
                                <li><i class="fa-solid fa-star"></i></li>
                                <li><i class="fa-solid fa-star"></i></li>
                                <li><i class="fa-solid fa-star"></i></li>
                            </ul>
                            <p class="text-muted fsize-14">5/5 Rating</p>
                            <p><strong>Good</strong></p>
                            <p class="fsize-14"><i>By: K.Sundara Murthy</i></p>
                            <p class="fsize-14 mb-0 text-right">Date: 27/Apr/2024 06:48PM</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-divider" id="contact">
                <div class="card-wrapper shadow card-border no-bottom-bg">
                    <div class="p-15px">
                        <h2 class="h4">Contact</h2>
                        <div class="form-group mb-3">
                            <div class="mb-2">
                                <input type="text" id="yourname_CNT" placeholder="Your Name" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <input type="email" id="emailid_CNT" placeholder="Email ID" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <input type="tel" id="phonenumber_CNT" placeholder="Your Contact" class="form-control" />
                            </div>
                            <div class="mb-2">
                                <textarea id="feedback_CNT" placeholder="Your Message" class="form-control"></textarea>
                            </div>
                            <button class="btn btn-primary btn_card w-100">Send!</button>
                        </div>
                    </div>
                    <footer class="footer">
                        <a href="#" class="fsize-14">emastersindia.net || Make Your Own Card Now || 2024</a>
                    </footer>
                </div>
            </div> */}
        </DigitalCardLayout>
    )
}

export default CurvyOrange