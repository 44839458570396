import { useEffect } from "react";

const PreviewLoader = ({ handler }) => {
    useEffect(() => {
        import("./Loader.css");
     }, [])
    return (
        <>
        {
            handler &&
            <div style={{ position: "fixed", width: "100%", height: "100%", zIndex: "100", top: "0", left: "0", background: "#000", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div class="spinner-box">
                <div class="configure-border-1">  
                    <div class="configure-core"></div>
                </div>  
                <div class="configure-border-2">
                    <div class="configure-core"></div>
                </div> 
                </div>
            </div>
        }
        </>
    )
}

export default PreviewLoader;