import { Link } from "react-router-dom";
import './Digitalcardlayout.css';
const DigitalCardLayout = ({ children }) => {
    return (
        <div className="main-wrapper">
            <div className="container card-container">
                { children }
            </div>
        </div>
    )
}

export default DigitalCardLayout;