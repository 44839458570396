import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const AdminHeader = ({ func }) => {
    const navigate = useNavigate();
    const handleAdminLogout = () => {
        Cookies.remove("admin");
        localStorage.removeItem("admin");
        navigate("/admin/login");
    }
    return (
        <header className="adminheader-layout">
            <div className="container-fluid">
                <nav className="nav-bar">
                    <button className="sidenav-toogler" onClick={() => func()}>
                        <i className='bx bx-menu' ></i>
                    </button>
                    <div className="header-right">
                        <div className="nav-item">
                            <Link to="/admin/notifications" className="nav-link icon-s">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-lg" role="img" aria-hidden="true"><path fill="var(--ci-primary-color, currentColor)" d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z" className="ci-primary"></path></svg>
                            </Link>
                        </div>
                        <div className="nav-item dropdown">
                            <button className="header-profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/3.jpg" alt="profile"/>
                            </button>
                            <ul class="dropdown-menu">
                                <li><button className="dropdown-item d-flex align-items-center gap-1" onClick={handleAdminLogout}><i className='bx bx-log-out'></i> Logout</button></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default AdminHeader;