import { Link } from "react-router-dom";
import Layout from "../components/Layout"
import './Home.css';
import { useEffect } from "react";
import Counter from "../components/Counter";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import $ from 'jquery';
import { Autoplay } from 'swiper/modules';
const Home = () => {
    useEffect(() => {
        document.title = "Buy Digital Business Cards Online";        

        $(document).ready(function () {
            var cW = $(".services-layout .container").width();
            var pW = $(window).width();
            let phalf = pW / 2;
            var chalf =cW / 2;
            let space = phalf - chalf;
            let total = cW + space;
            $("#slider-wrapper").css("maxWidth", total);
        })

        $(window).on("resize", function () {
            var cW = $(".services-layout .container").width();
            var pW = $(window).width();
            let phalf = pW / 2;
            var chalf =cW / 2;
            let space = phalf - chalf;
            let total = cW + space;
            $("#slider-wrapper").css("maxWidth", total);
        })

    }, [])
    return (
        <Layout>
            <div className="hero-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="subheading">Welcome To, DigitalBusinessCards</div>
                            <h1 className="hero-heading">The Most Creative Modern Card <span>Platform</span></h1>
                            <p className="mb-4">Discover the ultimate hub for digital business cards. Elevate your networking game with customizable designs and seamless online ordering.</p>
                            <Link to="/create-card" className="primary-btn">Get Started <i className='bx bx-right-arrow-alt'></i></Link>
                        </div>
                    </div>
                </div>
                <img src="assets/images/heroright.png" alt="hero image" className="hero-image"/>
            </div>
            <div className="counter-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="counter-bx">
                                <span className="counter"><Counter start={0} end={100} duration={5000}/>+</span>
                                <div className="counter-subheading">Cards Made</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="counter-bx">
                                <span className="counter"><Counter start={0} end={200} duration={5000}/>+</span>
                                <div className="counter-subheading">Happy Clients</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="counter-bx">
                                <span className="counter"><Counter start={0} end={50} duration={5000}/>+</span>
                                <div className="counter-subheading">Positive Reviews</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-layout">
                <div className="container">
                    <div className="row justify-content-end m-0">
                        <div className="col-xl-6 p-0">
                            <div className="subheading">Our Services</div>
                            <h2 className="main-heading">Browse our<br/> services & <span>offerings</span></h2>
                            <Link to="/create-card" className="primary-btn mb-4">Get Started <i className='bx bx-right-arrow-alt'></i></Link>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
                            <div className="service-box">
                                <h3 className="service-heading">Make You Card</h3>
                                <p>"Make Your Card" is an innovative platform designed to give users complete creative control over crafting personalized greeting cards. With a myriad of customizable templates, fonts, colors, and graphics, users can effortlessly design unique cards tailored to any occasion.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
                            <div className="service-box">
                                <h3 className="service-heading">Get You Custom Card</h3>
                                <p>"Make Your Card" is an innovative platform designed to give users complete creative control over crafting personalized greeting cards. With a myriad of customizable templates, fonts, colors, and graphics, users can effortlessly design unique cards tailored to any occasion.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
                            <div className="service-box">
                                <h3 className="service-heading">Get Queries From The Card</h3>
                                <p>Get Your Queries is a versatile and dynamic platform designed to cater to various needs and questions users may have. Whether it's seeking answers to complex problems, exploring curiosity about a wide array of topics, or finding solutions to everyday challenges, Get Your Queries aims to provide comprehensive assistance.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
                            <div className="service-box">
                                <h3 className="service-heading">Collect Feedbacks From The Card</h3>
                                <p>Collecting feedback is akin to tuning into the heartbeat of your audience or customers. It's the process of actively seeking and gathering insights, opinions, and evaluations on a product, service, or experience.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
                            <div className="service-box">
                                <h3 className="service-heading">Showcase Products In The Card</h3>
                                <p>Unveil a world of ingenuity with our showcase products, meticulously crafted to redefine excellence in their respective domains. From cutting-edge technology to timeless craftsmanship, each product embodies our commitment to innovation and quality. Discover the pinnacle of design, functionality, and performance as we invite you to explore our curated selection of groundbreaking offerings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="feedback-layout" style={{ display: "inline-block", width: "100%" }}>
                <div className="container">
                    <div className="feedback-heading">
                        <div className="subheading">Our Testimonials</div>
                        <h2 className="main-heading">What our clients are <span>saying</span></h2>
                    </div>
                </div>
                <div className="container-fluid p-0">
                <div id="slider-wrapper" style={{ float: "right" }}>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        breakpoints={{
                            0: {
                                slidesPerView: 1
                            },
                            800: {
                                slidesPerView: 2
                            },
                            1200: {
                                slidesPerView: 3
                            },
                            1800: {
                                slidesPerView: 4
                            },
                            3000: {
                                slidesPerView: 5
                            }
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                    >
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonial-box">
                                <p>I recently started using a digital business card, and it has transformed the way I network. The convenience of instantly sharing my contact information with just a tap is unbeatable. It’s eco-friendly, cost-effective, and leaves a lasting impression. I love that I can update my details anytime without reprinting cards. It's a modern solution that fits perfectly with today’s digital world. Highly recommended for anyone looking to streamline their professional connections!</p>
                                <div className="testimonial-bottom">
                                    <i>
                                        <img src="assets/images/avatar.png" alt="writer"/>
                                    </i>
                                    <div className="testimonialbottom-inner">
                                        <div className="testimonial-name">Mahesh</div>
                                        <div className="testimonial-designation">CEO</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                </div>
            </div> */}
        </Layout>
    )
}

export default Home;