import { Link } from "react-router-dom";
import AdminBreadCrumb from "../components/AdminBreadCrumb";
import AdminLayout from "../components/AdminLayout"
import { useEffect, useState } from "react";
import { backendUrl } from "../data/Url";
import axios from "axios";
const Dashboard = () => {

    const [regCount, setRegCount] = useState(0);
    const [enqCount, setEnqCount] = useState(0);
    const [themeCount, setThemeCount] = useState(0);
    useEffect(() => {
        const fetchRegistrations = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-registrations`);
                setRegCount(res.data.length)
            } catch (error) {
                console.log(error);
            }
        }
        fetchRegistrations();
        const fetchEnquiries = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-enquiry`);
                setEnqCount(res.data.length);
            } catch (error) {
                console.log(error);
            }
        }
        fetchEnquiries();
        const fetchTheme = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-theme`);
                setThemeCount(res.data.length)
            } catch (error) {
                console.log(error)
            }
        }

        fetchTheme();
     }, [])

    return (
        <AdminLayout>
            <AdminBreadCrumb title="Dashboard"/>
            <div className='main-layout'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="card bg-primary text-white">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Registrations</div>
                                        <Link to="/admin/registrations" className="text-white h-18px"><i className='bx bx-right-arrow-circle' ></i></Link>
                                    </div>
                                    <div className="dash-count">{regCount}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="card bg-info text-white">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Enquiries</div>
                                        <Link to="/admin/enquiries" className="text-white h-18px"><i className='bx bx-right-arrow-circle' ></i></Link>
                                    </div>
                                    <div className="dash-count">{enqCount}</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="card bg-warning text-white">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Orders</div>
                                        <Link to="/admin/orders" className="text-white h-18px"><i className='bx bx-right-arrow-circle' ></i></Link>
                                    </div>
                                    <div className="dash-count">2</div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="card bg-danger text-white">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Total Themes</div>
                                        <Link to="/admin/themes" className="text-white h-18px"><i className='bx bx-right-arrow-circle' ></i></Link>
                                    </div>
                                    <div className="dash-count">{themeCount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Dashboard;