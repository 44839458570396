import { Link } from 'react-router-dom';
const AdminBreadCrumb = ({ title }) => {
    return (
        <div className='container-fluid crum-container'>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{ title }</li>
                </ol>
            </nav>
        </div>
    )
}

export default AdminBreadCrumb;