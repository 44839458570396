import { useEffect } from "react";
import DigitalCardLayout from "../components/DigitalCardLayout"

const ClassyPurpleStatic = () => {
    useEffect(() => {
        import("./Classy-purple.css");
     }, [])
    return (
        <DigitalCardLayout>
            <div class="box-divider" id="basic">
    <div class="card-wrpper shadow">
        <div class="card-head">
            <div class="card-head-logo">
                <img src="/assets/images/card_logo.png" alt="logo" />
            </div>
            <h1 class="h2 text-white profile_name mb-4">John Doe</h1>
            <div class="profile_image_overlapper">
                <p class="text-white m-0">CEO</p>
                <p class="text-white mb-0">John Associates</p>
            </div>
        </div>
        <div class="profile_layout">
            <div class="profile-image">
                <img src="/assets/images/johndoe.jpg" alt="profilepic" />
            </div>
        </div>
        <div class="profile_image_overlapper" style={{ marginTop: "-39px", position: "relative", zIndex: "3" }}>
            <ul class="social_box">
                <li>
                    <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                </li>
                <li>
                    <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                </li>
                <li>
                    <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                </li>
                <li>
                    <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                </li>
                <li>
                    <a href="#" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                </li>
            </ul>
        </div>
        <ul class="contact_box">
            <li>
                <div class="contact_icon">
                    <i class='bx bxs-phone'></i>
                </div>
                <div class="contact_content">
                    <a href="tel:#">+91 9830565580</a>
                </div>
            </li>
            <li>
                <div class="contact_icon">
                    <i class='bx bxs-envelope'></i>
                </div>
                <div class="contact_content">
                    <a href="mailto:#">johndoe@example.com</a>
                </div>
            </li>
            <li>
                <div class="contact_icon">
                    <i class='bx bx-globe'></i>
                </div>
                <div class="contact_content">
                    <a href="#" target="_blank">example.com</a>
                </div>
            </li>
            <li>
                <div class="contact_icon">
                    <i class='bx bxs-map'></i>
                </div>
                <div class="contact_content">
                    <a href="#" target="_blank">
                        33, Townshend Road, Purnashish Building, Ground Floor,
                        Kolkata -700025 West Bengal, India
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="box-divider" id="about">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">About</h2>
                <p>John Doe is a pseudonym used to represent an anonymous or hypothetical individual, often in legal contexts, where the true identity is either unknown or intentionally withheld. This generic name has become emblematic of anonymity in various fields, from legal proceedings to literature and film. John Doe serves as a placeholder, embodying the everyman or the common person, devoid of specific characteristics or identity markers.</p>
                <p>In legal contexts, John Doe is frequently employed when referring to unidentified parties in lawsuits or criminal cases. For instance, a plaintiff might file a lawsuit against "John Doe" if they are unaware of the defendant's true identity but wish to proceed with legal action. Similarly, law enforcement might issue a warrant for the arrest of a suspect known only as John Doe until their identity is determined.</p>
                <p>Outside of the legal realm, the name John Doe has permeated popular culture, appearing in various works of fiction, such as novels, films, and television shows. In these contexts, John Doe often represents an enigmatic figure, shrouded in mystery or operating under an assumed identity. This archetype has been explored in numerous genres, including crime thrillers, psychological dramas, and science fiction, adding to its enduring appeal and cultural significance.</p>
                <p>Beyond its use in formal and creative contexts, John Doe has also found its way into everyday language as a symbol of anonymity and universality. When individuals wish to convey a sense of anonymity or represent the average person, they might use the term "John Doe" or its female counterpart, "Jane Doe." This widespread usage underscores the enduring relevance of John Doe as a symbol of anonymity and the universal human experience.</p>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="shop">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">Shop Online</h2>
                <div class="row">
                    <div class="col-6">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                                <span class="text-muted">₹ 885</span>
                            </div>
                            <button class="btn btn-primary w-100">Buy</button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                                <span class="text-muted">₹ 885</span>
                            </div>
                            <button class="btn btn-primary w-100">Buy</button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                                <span class="text-muted">₹ 885</span>
                            </div>
                            <button class="btn btn-primary w-100">Buy</button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                                <span class="text-muted">₹ 885</span>
                            </div>
                            <button class="btn btn-primary w-100">Buy</button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                                <span class="text-muted">₹ 885</span>
                            </div>
                            <button class="btn btn-primary w-100">Buy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="product">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">Products & Services</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                            </div>
                            <button class="btn btn-primary w-100">Enquiry</button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                            </div>
                            <button class="btn btn-primary w-100">Enquiry</button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                            </div>
                            <button class="btn btn-primary w-100">Enquiry</button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                            </div>
                            <button class="btn btn-primary w-100">Enquiry</button>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                            <div class="product-content text-center">
                                <h3 class="h6">DSC</h3>
                            </div>
                            <button class="btn btn-primary w-100">Enquiry</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="gallery">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">Gallery</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="product_box">
                            <div class="product-image">
                                <img src="~/assets-new/img/product/02.jpg" alt="product" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="payment">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">Payment Info</h2>
                <div class="h6">Bank Account Details:-</div>
                <div class="table-responsive">
                    <table class="table bankdetails">
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>:</td>
                                <td>JOHN ASSOCIATES</td>
                            </tr>
                            <tr>
                                <td>Account No.</td>
                                <td>:</td>
                                <td>1812897529</td>
                            </tr>
                            <tr>
                                <td>IFSC Code</td>
                                <td>:</td>
                                <td>KKBK0001383</td>
                            </tr>
                            <tr>
                                <td>Bank Name</td>
                                <td>:</td>
                                <td>Kotak Mahindra Bank</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="qr-code">
                    <img src="~/assets-new/img/product/qr.webp" alt="qr" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="contact">
    <div class="card-wrpper shadow">
        <div class="section-inner">
            <div class="body-content">
                <h2 class="h4 section_heading">Contact</h2>
                <div class="form-group mb-3">
                    <div class="mb-2">
                        <input type="text" id="yourname_CNT" placeholder="Your Name" class="form-control" />
                    </div>
                    <div class="mb-2">
                        <input type="email" id="emailid_CNT" placeholder="Email ID" class="form-control" />
                    </div>
                    <div class="mb-2">
                        <input type="tel" id="phonenumber_CNT" placeholder="Your Contact" class="form-control" />
                    </div>
                    <div class="mb-2">
                        <textarea id="feedback_CNT" placeholder="Your Message" class="form-control"></textarea>
                    </div>
                    <button class="btn btn-primary w-100">Send!</button>
                </div>
            </div>
        </div>
        <footer class="footer">
            <a href="#" class="fsize-14">emastersindia.net || Make Your Own Card Now || 2024</a>
        </footer>
    </div>
</div>
        </DigitalCardLayout>
    )
}

export default ClassyPurpleStatic;