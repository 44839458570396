import { useEffect } from "react";
import Layout from "../components/Layout"
import ProfileCardHeader from "../components/ProfileCardHeader";
import UserProfileCommonPanel from "../components/UserProfileCommonPanel";
import Cookies from "js-cookie";
const UserProfileOrders = () => {
    useEffect(() => { 
        import("./MyOrders.css");
    }, [])
    const user = JSON.parse(Cookies.get("user"));
    return (
        <Layout>
            <div className="container">
                <div className="row userprofile_layout">
                    <UserProfileCommonPanel user={user}/>
                    <div className="col-md-9">
                        <div className="card border-0">
                            <ProfileCardHeader title="Orders"/>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped card-table">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Order ID</th>
                                                <th>Order Place Date</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>93C7BCP2DXKQ</td>
                                                <td>March 08,2024</td>
                                                <td>₹ 1,398.00</td>
                                                <td>
                                                    <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#orderDetails"><i className="fa-solid fa-eye"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="orderDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Order Details</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h6 class="mb-3">Products</h6>
                            <div class="row mb-4">
                                <div class="col-md-1">
                                    <img src="https://nfccards.in/assets/images/Product/pvc-6.webp" alt="" style={{ width: "100%" }} />
                                </div>
                                <div class="col-md-6">
                                    <h4>NFC Cards</h4>
                                </div>
                                <div class="col-md-5">

                                    <p class="mb-0">Price: ₹ 1,298.00</p>
                                    <p class="mb-0">Quantity: 1</p>
                                </div>
                            </div>
                            <table class="table product_checkout_review_tabl">
                                <thead>
                                    <tr>
                                        <th colspan="3">
                                            Shipping Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="table_cs_wid_cls">Name</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_full_name_td">Nirali Bakhai</td>
                                    </tr>
                                    <tr>
                                        <td class="table_cs_wid_cls">Email ID</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_email_td">niralibakhai.em@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td class="table_cs_wid_cls">Phone</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_mobile_no_td">9831033714</td>
                                    </tr>
                                    <tr class="cname" style={{ display: "none" }}>
                                        <td class="table_cs_wid_cls">Company Name</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_cnumber">
                                            Emastersindia.net
                                        </td>
                                    </tr>
                                    <tr class="cgstn" style={{ display: "none" }}>
                                        <td class="table_cs_wid_cls">GST Number</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_cgstn">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="table_cs_wid_cls">Address</td>
                                        <td class="table_cs_wid_cls_one">:</td>
                                        <td class="ship_address_td">Purnashish Building, 33 Townshend Road, Ground Floor,Kolkata - 700025,West Bengal,India</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-4">
                                <p>Payment Status: <span class="bg-warning text-dark status" style={{ borderRadius: "60px" }}>Awaiting Details</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserProfileOrders;