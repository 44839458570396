import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Editor from "../components/Editor";
import Layout from "../components/Layout";
import phoneCodes from '../data/phonecode.json';
import $ from 'jquery';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { backendUrl, imageUrl } from "../data/Url";
const Createdigitalcard = ({ previewHandler }) => {
    const navigate = useNavigate();
    const selectRef = useRef(null);
    const [options, setOptions] = useState([]);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [themeData, setThemeData] = useState([]);
    useEffect(() => {

        if (document.querySelector("link[data-theme-css]")) {
            document.querySelector("link[data-theme-css]").remove();
        }

        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/fetch-all-theme`);
                setThemeData(res.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();

        setOptions(phoneCodes);
        $(selectRef.current).select2({
            width: "100%",
            dropdownParent: $(".phone-country-select")
        });

        // const phone_Code = $("#phone_code").val();
        // setPhoneCode(phone_Code);

        return () => {
            $(selectRef.current).select2('destroy')
        }
    }, [])

    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [galleries, setGalleries] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem("newcard") ? JSON.parse(localStorage.getItem("newcard")).Theme : 11);
    const handleThemeChange = (event) => {
        setSelectedTheme(parseInt(event.target.id, 10));
      };
    useEffect(() => {
        import("./Login.css");
        import("./Bootstrap-form.css");
        import("./Createcard.css");
    }, []);
    const addProduct = () => {
        setProducts([...products, {
            id: Date.now(),
            name: "",
            price: "",
            image: ""
        }])
    }
    const addService = () => {
        setServices([...services, {
            id: Date.now(), 
            name: "",
            image: ""
        }])
    }
    const addGallery = () => {
        setGalleries([...galleries, {
            id: Date.now(),
            image: ""
        }])
    }
    const removeProduct = (id) => {
        setProducts(products.filter((product) => product.id !== id));
    }
    const removeService = (id) => {
        setServices(services.filter((services) => services.id !== id));
    }
    const removeGallery = (id) => {
        setGalleries(galleries.filter((gallery) => gallery.id !== id));
    }
    const [cardName, setCardName] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).CardName : "");
    // const [companyLogo, setCompanyLogo] = useState(null);
    // const [profilePic, setProfilePic] = useState(null);
    
    const [name, setName] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).Name : "");
    const [designation, setDesignation] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).Designation : "");
    const [companyName, setCompanyName] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).CompanyName : "");
    const [facebookLink, setFacebookLink] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).FacebookLink : "");
    const [twitterLink, setTwitterLink] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).TwitterLink : "");
    const [instagramLink, setInstagramLink] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).InstagramLink : "");
    const [linkedinLink, setLinkedinLink] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).LinkedinLink : "");
    const [whatsappNumber, setWhatsappNumber] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).WhatsappNumber : ""); 
    const [phoneNumber, setPhoneNumber] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).PhoneNumber : "");
    const [email, setEmail] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).Email : "");
    const [website, setWebsite] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).Website : "");
    const [address, setAddress] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).Address : "");
    const [bankingName, setBankingName] = useState("");
    const [account, setAccount] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [bankName, setBankName] = useState("");
    const [qr, setQr] = useState(null);
    const [editor, setEditor] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).About : "");
    const [allowSeo, setAllowSeo] = useState(false);
    const [companyLogoImage, setCompanyLogoImage] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).CompanyLogo : "");
    const [profilePictureImage, setProfilePictureImage] = useState(JSON.parse(localStorage.getItem("newcard")) ? JSON.parse(localStorage.getItem("newcard")).ProfilePicture : "");
    const handleCompanyLogo = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size <= 10000) {
            if (selectedFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setCompanyLogoImage(reader.result);
                }
                reader.readAsDataURL(selectedFile);
            }
        } else {
            toast.warning("Maxium file size exceeded", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }
    const handleProfilePic = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size <= 300000) {
            if (selectedFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setProfilePictureImage(reader.result);
                }
                reader.readAsDataURL(selectedFile);
            }
        } else {
            toast.warning("Maxium file size exceeded", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }
    const handleQr = (e) => {
        const selectedFile = e.target.files[0];
        setQr(selectedFile);
    }
    const handleEditor = (content) => {
        setEditor(content);
    }
    const handleProductName = (id, newName) => {
        setProducts(products.map(el => 
            el.id === id ? { ...el, name: newName } : el
        ));
    }
    const handleServiceName = (id, newName) => {
        setServices(services.map(el =>
            el.id === id ? { ...el, name: newName } : el
        ))
    }
    const handleProductPrice = (id, newPrice) => {
        setProducts(products.map(el => 
            el.id === id ? { ...el, price: newPrice } : el
        ));
    }
    const handleProductImage = (id, newImage) => {
        setProducts(products.map(el => 
            el.id === id ? { ...el, image: newImage } : el
        ));
    }
    const handleServiceImge = (id, newImage) => {
        setServices(services.map(el => 
            el.id === id ? { ...el, image: newImage } : el
        ))
    }
    const handleGalleryImage = (id, newImage) => {
        setGalleries(galleries.map(el => 
            el.id === id ? { ...el, image: newImage } : el
        ))
    }
    const handleFileChange = (id, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            handleProductImage(id, reader.result);
        }
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const handleFileChange2 = (id, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            handleServiceImge(id, reader.result);
        }
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const handleFileChange3 = (id, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            handleGalleryImage(id, reader.result);
        }
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const handleAllowSeo = () => {
        setAllowSeo(!allowSeo);
    }

    const [cardNameValid, setCardNameValid] = useState(false);
    const [companyLogoValid, setCompanyLogoValid] = useState(false);
    const [prodilePictureValid, setProfilePictureValid] = useState(false);
    const [nameValid, setNameValid] = useState(false);
    const [designationValid, setDesignationValid] = useState(false);
    const [companyNameValid, setCompanyNameValid] = useState(false);
    const [facebookValid, setFacebookValid] = useState(false);
    const [twitterValid, setTwitterValid] = useState(false);
    const [instagramValid, setInstagramValid] = useState(false);
    const [linkedinValid, setLinkedinValid] = useState(false);
    const [whatsappValid, setWhatsappValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [websiteValid, setWebsiteValid] = useState(false);
    const [addressValid, setAddressValid] = useState(false);
    const [aboutValid, setAboutValid] = useState(false);
    const [bankingNameValid, setBnkingNameValid] = useState(false);
    const [accountValid, setAccountValid] = useState(false);
    const [ifscValid, setIfscValid] = useState(false);
    const [bankNameValid, setBankNameValid] = useState(false);
    const [qrValid, setQrValid] = useState(false);
    const [isWhatsapp, setIsWhatsapp] = useState(false);
    const handleCheckbox = () => {
        setIsWhatsapp(!isWhatsapp);
        if (!isWhatsapp) {
            setWhatsappNumber(phoneNumber);
        } else {
            setWhatsappNumber("");
        }

    }
    const handleSameNumber = (e) => {
        setPhoneNumber(e.target.value);
        if (isWhatsapp) {
            setWhatsappNumber(e.target.value);
        }
    }
    const handleSubmit = async () => {
        var valid = true;

        if (cardName.length == 0) {
            setCardNameValid(true);
            valid = false;
        } else {
            setCardNameValid(false);
        }
        if (companyLogoImage.length == 0) {
            setCompanyLogoValid(true);
            valid = false;
        } else {
            setCompanyLogoValid(false);
        }
        if (profilePictureImage.length == 0) {
            setProfilePictureValid(true);
            valid = false;
        } else {
            setProfilePictureValid(false);
        }
        if (name.length == 0) {
            setNameValid(true);
            valid = false;
        } else {
            setNameValid(false);
        }
        if (designation.length == 0) {
            setDesignationValid(true);
            valid = false;
        } else {
            setDesignationValid(false);
        }
        if (companyName.length == 0) {
            setCompanyNameValid(true);
            valid = false;
        } else {
            setCompanyNameValid(false);
        }
        if (facebookLink.length == 0) {
            setFacebookValid(true);
            valid = false;
        } else {
            setFacebookValid(false);
        }
        if (twitterLink.length == 0) {
            setTwitterValid(true);
            valid = false;
        } else {
            setTwitterValid(false);
        }
        if (instagramLink.length == 0) {
            setInstagramValid(true);
            valid = false;
        } else {
            setInstagramValid(false);
        }
        if (linkedinLink.length == 0) {
            setLinkedinValid(true);
            valid = false;
        } else {
            setLinkedinValid(false);
        }
        if (whatsappNumber.length == 0) {
            setWhatsappValid(true);
            valid = false;
        } else {
            setWhatsappValid(false);
        }
        if (phoneNumber.length == 0) {
            setPhoneValid(true);
            valid = false;
        } else {
            setPhoneValid(false);
        }
        if (email.length == 0) {
            setEmailValid(true);
            valid = false;
        } else {
            setEmailValid(false);
        }
        if (website.length == 0) {
            setWebsiteValid(true);
            valid = false;
        } else {
            setWebsiteValid(false);
        }
        if (address.length == 0) {
            setAddressValid(true);
            valid = false;
        } else {
            setWebsiteValid(false);
        }


        // if (editor.length == 0) {
        //     setAboutValid(true);
        //     valid = false;
        // } else {
        //     setAboutValid(false);
        // }
        // if (bankingName.length == 0) {
        //     setBankNameValid(true);
        //     valid = false;
        // } else {
        //     setBankNameValid(false);
        // }
        // if (account.length == 0) {
        //     setAccountValid(true);
        //     valid = false;
        // } else {
        //     setAccountValid(false);
        // }
        // if (ifsc.length == 0) {
        //     setIfscValid(true);
        //     valid = false;
        // } else {
        //     setIfscValid(false);
        // }
        // if (bankName.length == 0) {
        //     setBankNameValid(true);
        //     valid = false;
        // } else {
        //     setBankNameValid(false);
        // }
        // if (qr == null) {
        //     setQrValid(true);
        //     valid = false;
        // } else {
        //     setQrValid(false);
        // }

        if (valid) {

            let userID = 0;
            if (Cookies.get("user")) {
                userID = JSON.parse(Cookies.get("user")).userid;
            }
            const formData = new FormData();
            formData.append("CardName", cardName);
            formData.append("CompanyLogo", companyLogoImage);
            formData.append("ProfilePicture", profilePictureImage);
            formData.append("Name", name);
            formData.append("Designation", designation);
            formData.append("CompanyName", companyName);
            formData.append("FacebookLink", facebookLink);
            formData.append("TwitterLink", twitterLink);
            formData.append("InstagramLink", instagramLink);
            formData.append("LinkedinLink", linkedinLink);
            formData.append("WhatsappNumber", whatsappNumber);
            formData.append("PhoneCode", phoneCode);
            formData.append("PhoneNumber", phoneNumber);
            formData.append("Email", email);
            formData.append("Website", website);
            formData.append("Address", address);
            formData.append("UserID", userID);
            formData.append("Theame", selectedTheme);
            formData.append("AllowSeo", allowSeo);
            console.log(companyLogoImage);
            const res = await axios.post(`${backendUrl}/save-card`, formData, {
                'Content-Type': 'multipart/form-data'
            });
            if (localStorage.getItem("newcard")) {
                localStorage.removeItem("newcard");
            }
            const status = res.data.Status;
            if (status === 200) {
                toast.success(res.data.Message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate(`/card/${res.data.CardID}`)
                })
            }

        }
    }
    
    const handlePreview = async () => {
        var valid = true;

        if (cardName.length == 0) {
            setCardNameValid(true);
            valid = false;
        } else {
            setCardNameValid(false);
        }
        if (companyLogoImage.length == 0) {
            setCompanyLogoValid(true);
            valid = false;
        } else {
            setCompanyLogoValid(false);
        }
        if (profilePictureImage.length == 0) {
            setProfilePictureValid(true);
            valid = false;
        } else {
            setProfilePictureValid(false);
        }
        if (name.length == 0) {
            setNameValid(true);
            valid = false;
        } else {
            setNameValid(false);
        }
        if (designation.length == 0) {
            setDesignationValid(true);
            valid = false;
        } else {
            setDesignationValid(false);
        }
        if (companyName.length == 0) {
            setCompanyNameValid(true);
            valid = false;
        } else {
            setCompanyNameValid(false);
        }
        if (facebookLink.length == 0) {
            setFacebookValid(true);
            valid = false;
        } else {
            setFacebookValid(false);
        }
        if (twitterLink.length == 0) {
            setTwitterValid(true);
            valid = false;
        } else {
            setTwitterValid(false);
        }
        if (instagramLink.length == 0) {
            setInstagramValid(true);
            valid = false;
        } else {
            setInstagramValid(false);
        }
        if (linkedinLink.length == 0) {
            setLinkedinValid(true);
            valid = false;
        } else {
            setLinkedinValid(false);
        }
        if (whatsappNumber.length == 0) {
            setWhatsappValid(true);
            valid = false;
        } else {
            setWhatsappValid(false);
        }
        if (phoneNumber.length == 0) {
            setPhoneValid(true);
            valid = false;
        } else {
            setPhoneValid(false);
        }
        if (email.length == 0) {
            setEmailValid(true);
            valid = false;
        } else {
            setEmailValid(false);
        }
        if (website.length == 0) {
            setWebsiteValid(true);
            valid = false;
        } else {
            setWebsiteValid(false);
        }
        if (address.length == 0) {
            setAddressValid(true);
            valid = false;
        } else {
            setAddressValid(false);
        }
        if (editor.length == 0) {
            setAboutValid(true);
            valid = false;
        } else {
            setAboutValid(false);
        }

        
        // if (bankingName.length == 0) {
        //     setBankNameValid(true);
        //     valid = false;
        // } else {
        //     setBankNameValid(false);
        // }
        // if (account.length == 0) {
        //     setAccountValid(true);
        //     valid = false;
        // } else {
        //     setAccountValid(false);
        // }
        // if (ifsc.length == 0) {
        //     setIfscValid(true);
        //     valid = false;
        // } else {
        //     setIfscValid(false);
        // }
        // if (bankName.length == 0) {
        //     setBankNameValid(true);
        //     valid = false;
        // } else {
        //     setBankNameValid(false);
        // }
        // if (qr == null) {
        //     setQrValid(true);
        //     valid = false;
        // } else {
        //     setQrValid(false);
        // }

        if (valid) {

            let userID = 0;
            if (Cookies.get("user")) {
                userID = JSON.parse(Cookies.get("user")).userid;
            }
            const newCard = {
                CardName: cardName,
                CompanyLogo: companyLogoImage,
                ProfilePicture: profilePictureImage,
                Name: name,
                Designation: designation,
                CompanyName: companyName,
                FacebookLink: facebookLink,
                TwitterLink: twitterLink,
                InstagramLink: instagramLink,
                LinkedinLink: linkedinLink,
                WhatsappNumber: whatsappNumber,
                PhoneCode: phoneCode,
                PhoneNumber: phoneNumber,
                Email: email,
                Website: website,
                Address: address,
                About: editor,
                isPreview: true,
                AllowSeo: allowSeo,
                Theme: selectedTheme
            }
            if (localStorage.getItem("newcard")) {
                localStorage.removeItem("newcard");
            }
            localStorage.setItem("newcard", JSON.stringify(newCard));

            previewHandler(newCard);
            const routeRedirect = themeData.find(el => el.ID === selectedTheme).ThemeRoute;
            navigate(`/preview/${routeRedirect}`);
        }
    }
    return (
        <Layout>
            <div className="inner-page-wrapper">
                <div className="container">
                    <div className="subheading">Create Your Own</div>
                    <h1 className="main-heading">Digital Visiting <span>Card</span></h1>
                    <div className="row pt-5">
                        <div className="col-md-6 mb-4">
                            <label className="form-label d-block">Card Logo<span className="text-danger">*</span></label>
                            <div className="custom-input">
                                <img src={companyLogoImage || "../assets/images/logo_placeholder.png"} alt="company-logo"/>
                                <input type="file" id="companylogo" accept="image/*" onChange={handleCompanyLogo} required/>    
                            </div>
                            {companyLogoValid && <p className="text-danger">Please upload a company logo!</p>}
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label">Card Name<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" onChange={(e) => setCardName(e.target.value)} title="" value={cardName} required/>
                            {cardNameValid && <p className="text-danger">Please upload a card name!</p>}
                        </div>
                        <div class="col-md-12 mb-2">
                            <h5 className="mb-0">Personal Informations :-</h5>
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label d-block">Profile Picture<span className="text-danger">*</span></label>
                            <div className="custom-input custom-input2">
                                <img src={profilePictureImage || "../assets/images/profile_placeholder.png"} alt="Company Logo"/>
                                <input type="file" id="profilepicture" className="form-control" title="" accept="image/*" onChange={handleProfilePic} required/>
                            </div>
                            {prodilePictureValid && <p className="text-danger">Please upload a profile picture!</p>}
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label">Name<span className="text-danger">*</span></label>
                            <input id="username" type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required/>
                            {nameValid && <p className="text-danger">Please enter a name!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label">Designation<span className="text-danger">*</span></label>
                            <input id="designation" type="text" className="form-control" value={designation} onChange={(e) => setDesignation(e.target.value)} required/>
                            {designationValid && <p className="text-danger">Please enter designaton!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="phonenumber">Phone Number<span className="text-danger">*</span></label>
                            <div className="row m-0">
                                <div className="col-3 p-0">
                                    <div className='phone-country-select'>
                                        <select ref={selectRef} id="phone_code">
                                            {
                                                options.map((el, index) => {
                                                    return (
                                                        <option key={index} value={el.dial_code} selected={el.dial_code === phoneCode}>
                                                            {el.dial_code} ({el.name})
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-9 p-0">
                                    <div className="position-relative">
                                        <input type="tel" id="phonenumber" className="form-control" onChange={handleSameNumber} value={phoneNumber} required/>
                                        <div className="form-check" style={{ position: "absolute", top: "12px", right: "5px" }}>
                                            <input className="form-check-input" type="checkbox" checked={isWhatsapp} onChange={handleCheckbox} style={{ marginTop: "1px", backgroundColor: "#868686" }}/>
                                            <label className="form-check-label text-black"><i className='bx bxl-whatsapp' style={{ color: "#00e676" }}></i></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {phoneValid && <p className="text-danger">Please enter phone number!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="email">Email<span className="text-danger">*</span></label>
                            <input type="email" id="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            {emailValid && <p className="text-danger">Please enter email!</p>}
                        </div>
                        <div className="col-md-12 mb-4">
                            <label className="form-label" for="address">Adress<span className="text-danger">*</span></label>
                            <input type="text" id="address" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} required/>
                            {addressValid && <p className="text-danger">Please enter address!</p>}
                        </div>
                        <div className="col-md-12 mb-2">
                            <h5 className="mb-0">Professional Informations :-</h5>
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="companyname">Company Name<span className="text-danger">*</span></label>
                            <input id="companyname" type="text" className="form-control" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required/>
                            {companyNameValid && <p className="text-danger">Please enter company name!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="website">Website<span className="text-danger">*</span></label>
                            <input type="url" id="website" className="form-control" value={website} onChange={(e) => setWebsite(e.target.value)} required/>
                            {websiteValid && <p className="text-danger">Please enter website!</p>}
                        </div>
                        <div className="col-md-12 mb-2">
                            <h5 className="mb-0">Social Media Links :-</h5>
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="facebooklink">Facebook Link<span className="text-danger">*</span></label>
                            <input type="url" id="facebooklink" className="form-control" value={facebookLink} onChange={(e) => setFacebookLink(e.target.value)} required/>
                            {facebookValid && <p className="text-danger">Please enter link!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="twitterlink">Twitter Link<span className="text-danger">*</span></label>
                            <input type="url" id="twitterlink" className="form-control" value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} required/>
                            {twitterValid && <p className="text-danger">Please enter link!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="instagramlink">Instagram Link<span className="text-danger">*</span></label>
                            <input type="url" id="instagramlink" className="form-control" value={instagramLink} onChange={(e) => setInstagramLink(e.target.value)} required/>
                            {instagramValid && <p className="text-danger">Please enter link!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="linkedinlink">Linkedin Link<span className="text-danger">*</span></label>
                            <input type="url" id="linkedinlink" className="form-control" value={linkedinLink} onChange={(e) => setLinkedinLink(e.target.value)} required/>
                            {linkedinValid && <p className="text-danger">Please enter link!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label className="form-label" for="whatsappnumber">Whatsapp Number<span className="text-danger">*</span></label>
                            <input type="tel" id="whatsappnumber" className="form-control" value={whatsappNumber} onChange={(e) => setWhatsappNumber(e.target.value)} required/>
                            {whatsappValid && <p className="text-danger">Please enter whatsapp number!</p>}
                        </div>
                        <div className="col-md-6 mb-4"></div>
                        <div className="col-md-12 mb-4">
                            <h5>About Section</h5>
                            <Editor value={editor} onChange={handleEditor} height="400px"/>
                            {aboutValid && <p className="text-danger">Please enter about!</p>}
                        </div>
                        {/* 
                        <div className="col-md-12 mb-2">
                            <h5>Shop Online Section</h5>
                            <div className="row" id="shop_row">
                                {
                                    products.map((product) => {
                                        return (
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" key={product.id}>
                                                <div className="product_box">
                                                    <button className="minus_btn" onClick={() => removeProduct(product.id)}>-</button>
                                                    <div className="product_image">
                                                        <img src={product.image || "assets/images/dummy_profile.jpg"} alt="" />
                                                        <input type="file" className="inputcustom_file" onChange={(e) => handleFileChange(product.id, e)} accept="image/*" id="productimage{{index}}" />
                                                    </div>
                                                    <input type="text" placeholder="Product Name" className="form-control mb-1" value={product.name} onChange={(e) => handleProductName(product.id, e.target.value)}/>
                                                    <input type="text" placeholder="Product Price" className="form-control" value={product.price} onChange={(e) => handleProductPrice(product.id, e.target.value)}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="productadd_box product_box">
                                        <button id="addnewProduct" onClick={addProduct}>+</button>
                                        <span><i className="fa-solid fa-circle-plus"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <h5>Products & Services Section</h5>
                            <div className="row" id="services_row">
                                {
                                    services.map((service) => {
                                        return (
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" key={service.id}>
                                                <div className="product_box">
                                                    <button className="minus_btn" onClick={() => removeService(service.id)}>-</button>
                                                    <div className="product_image">
                                                        <img src={service.image || "assets/images/dummy_profile.jpg"} alt=""/>
                                                        <input type="file" className="inputcustom_file" accept="image/*" onChange={(e) => handleFileChange2(service.id, e)} id="serviceimage{{index}}" />
                                                    </div>
                                                    <input type="text" placeholder="Service Name" className="form-control mb-1" value={service.name} onChange={(e) => handleServiceName(service.id, e.target.value)}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="productadd_box product_box">
                                        <button id="addnewService" onClick={addService}>+</button>
                                        <span><i className="fa-solid fa-circle-plus"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <h5>Gallery</h5>
                            <div className="row" id="gallery_row">
                                {
                                    galleries.map((gallery) => {
                                        return (
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" key={gallery.id}>
                                                <div className="product_box mb-0">
                                                    <button className="minus_btn" onClick={() => removeGallery(gallery.id)}>-</button>
                                                    <div className="product_image">
                                                        <img src={gallery.image || "assets/images/dummy_profile.jpg"} alt="" />
                                                        <input type="file" className="inputcustom_file" accept="image/*" onChange={(e) => handleFileChange3(gallery.id, e)} id="galleryimage1" />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="productadd_box product_box">
                                        <button id="addnewGallery" onClick={addGallery}>+</button>
                                        <span><i className="fa-solid fa-circle-plus"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-2">
                            <h5 className="mb-0">Payment Details</h5>
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="bankingname" className="form-label">Name<span className="text-danger">*</span></label>
                            <input type="text" id="bankingname" className="form-control" onChange={(e) => setBankingName(e.target.value)} required/>
                            {bankNameValid && <p className="text-danger">Please enter the account holder name!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="accountno" className="form-label">Account No.<span className="text-danger">*</span></label>
                            <input id="accountno" type="text" className="form-control" onChange={(e) => setAccount(e.target.value)} required/>
                            {accountValid && <p className="text-danger">Please enter an account number!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="ifsc" className="form-label">IFSC Code<span className="text-danger">*</span></label>
                            <input id="ifsc" type="text" className="form-control" onChange={(e) => setIfsc(e.target.value)} required/>
                            {ifscValid && <p className="text-danger">Please enter an ifsc code!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="bankname" className="form-label">Bank Name<span className="text-danger">*</span></label>
                            <input id="bankname" type="text" className="form-control" onChange={(e) => setBankName(e.target.value)} required/>
                            {bankNameValid && <p className="text-danger">Please enter a bank name!</p>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label for="payment-qrcode" className="form-label">Upload Payment QR Code<span className="text-danger">*</span></label>
                            <input type="file" accept="image/*" className="form-control" onChange={handleQr} id="payment-qrcode"/>
                            {qrValid && <p className="text-danger">Please upload the payment QR code!</p>}
                        </div> */}
                    </div>
                    <h5 className="mb-3">Choose Theme</h5>
                    <Swiper
                        slidesPerView={8}
                        pagination={{ dynamicBullets: true }}
                        modules={[Pagination]}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                autoplay: true
                            },
                            480: {
                                slidesPerView: 3,
                                autoplay: true
                            },
                            640: {
                                slidesPerView: 3,
                                autoplay: true
                            },
                            768: {
                                slidesPerView: 4,
                                autoplay: true
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {
                            themeData.map((el, index) => {
                                return (
                                    <SwiperSlide key={el.ID}>
                                        <div className="choose_theme_box">
                                            <label htmlFor={el.ID}>
                                                <span className="d-block mb-1">{el.ThemeName}</span>
                                                <img src={`${imageUrl}${el.ThemeImage}`} alt="corporateblue" />
                                            </label>
                                            <input type="radio"
                                            id={el.ID}
                                            className="form-check-input"
                                            checked={selectedTheme === el.ID}
                                            name="theme"
                                            onChange={handleThemeChange}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>                    
                    <div className="d-flex justify-content-end gap-3 align-items-center createcard-buttons-responsive">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="allowSeo" onChange={handleAllowSeo}/>
                            <label className="form-check-label" htmlFor="allowSeo" style={{lineHeight: "29px"}}>Allow SEO <span className="tooltip-container"><i className='bx bxs-info-circle'></i><span className="custom-tooltip">If any one searches by your name in search engines then they will get this information directly.</span></span></label>
                        </div>
                        <button className="secondary-btn" onClick={() => handlePreview()}>Go To Preview</button>
                        {/* <button className="primary-btn" onClick={() => handleSubmit()}>Create</button> */}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Createdigitalcard;