import { useEffect, useState } from "react";
import AdminBreadCrumb from "../components/AdminBreadCrumb";
import AdminLayout from "../components/AdminLayout";
import $ from 'jquery';
import axios from "axios";
import { backendUrl } from "../data/Url";
const Registrations = () => {
    const [regData, setRegData] = useState([]);
    // const regData = [
    //     {
    //         "_id": 1,
    //         "FirstName": "Srijan",
    //         "LastName": "Sinha",
    //         "PhoneCode": "+91",
    //         "Phone": "6290342523",
    //         "Email": "s.r.j.s.n2017@gmail.com",
    //         "Company": "Emastersindia.net",
    //         "Password": "Enpl@0407",
    //         "Address": "140/1/1, Shibpur Road",
    //         "Country": "India",
    //         "State": "West Bengal",
    //         "City": "Howrah",
    //         "PinCode": "711102"
    //     },
    //     {
    //         "_id": 2,
    //         "FirstName": "John",
    //         "LastName": "Doe",
    //         "PhoneCode": "+1",
    //         "Phone": "5551234567",
    //         "Email": "john.doe@example.com",
    //         "Company": "ExampleCorp",
    //         "Password": "Password123!",
    //         "Address": "123 Main St",
    //         "Country": "USA",
    //         "State": "California",
    //         "City": "Los Angeles",
    //         "PinCode": "90001"
    //     },
    //     {
    //         "_id": 3,
    //         "FirstName": "Jane",
    //         "LastName": "Smith",
    //         "PhoneCode": "+44",
    //         "Phone": "7700123456",
    //         "Email": "jane.smith@example.co.uk",
    //         "Company": "SampleLtd",
    //         "Password": "SecurePass456!",
    //         "Address": "456 Elm St",
    //         "Country": "UK",
    //         "State": "England",
    //         "City": "London",
    //         "PinCode": "E1 6AN"
    //     },
    //     {
    //         "_id": 4,
    //         "FirstName": "Akira",
    //         "LastName": "Kurosawa",
    //         "PhoneCode": "+81",
    //         "Phone": "8091234567",
    //         "Email": "akira.kurosawa@example.jp",
    //         "Company": "TokyoWorks",
    //         "Password": "Tokyo2024!",
    //         "Address": "789 Sakura Ave",
    //         "Country": "Japan",
    //         "State": "Tokyo",
    //         "City": "Shibuya",
    //         "PinCode": "150-0002"
    //     },
    //     {
    //         "_id": 5,
    //         "FirstName": "Maria",
    //         "LastName": "Garcia",
    //         "PhoneCode": "+34",
    //         "Phone": "612345678",
    //         "Email": "maria.garcia@example.es",
    //         "Company": "MadridSolutions",
    //         "Password": "Espana2024!",
    //         "Address": "101 Calle Mayor",
    //         "Country": "Spain",
    //         "State": "Madrid",
    //         "City": "Madrid",
    //         "PinCode": "28013"
    //     },
    //     {
    //         "_id": 6,
    //         "FirstName": "Liu",
    //         "LastName": "Wei",
    //         "PhoneCode": "+86",
    //         "Phone": "13800138000",
    //         "Email": "liu.wei@example.cn",
    //         "Company": "BeijingTech",
    //         "Password": "China2024!",
    //         "Address": "102 Great Wall St",
    //         "Country": "China",
    //         "State": "Beijing",
    //         "City": "Beijing",
    //         "PinCode": "100000"
    //     },
    //     {
    //         "_id": 7,
    //         "FirstName": "Elena",
    //         "LastName": "Rossi",
    //         "PhoneCode": "+39",
    //         "Phone": "3456789012",
    //         "Email": "elena.rossi@example.it",
    //         "Company": "MilanDesign",
    //         "Password": "Italia2024!",
    //         "Address": "103 Via Roma",
    //         "Country": "Italy",
    //         "State": "Lombardy",
    //         "City": "Milan",
    //         "PinCode": "20121"
    //     },
    //     {
    //         "_id": 8,
    //         "FirstName": "Pierre",
    //         "LastName": "Dupont",
    //         "PhoneCode": "+33",
    //         "Phone": "612345678",
    //         "Email": "pierre.dupont@example.fr",
    //         "Company": "ParisInnovations",
    //         "Password": "France2024!",
    //         "Address": "104 Rue de Rivoli",
    //         "Country": "France",
    //         "State": "Île-de-France",
    //         "City": "Paris",
    //         "PinCode": "75001"
    //     },
    //     {
    //         "_id": 9,
    //         "FirstName": "Nina",
    //         "LastName": "Kovalenko",
    //         "PhoneCode": "+7",
    //         "Phone": "9123456789",
    //         "Email": "nina.kovalenko@example.ru",
    //         "Company": "MoscowIndustries",
    //         "Password": "Russia2024!",
    //         "Address": "105 Red Square",
    //         "Country": "Russia",
    //         "State": "Moscow",
    //         "City": "Moscow",
    //         "PinCode": "101000"
    //     },
    //     {
    //         "_id": 10,
    //         "FirstName": "Carlos",
    //         "LastName": "Martinez",
    //         "PhoneCode": "+52",
    //         "Phone": "5512345678",
    //         "Email": "carlos.martinez@example.mx",
    //         "Company": "MexicoCitySolutions",
    //         "Password": "Mexico2024!",
    //         "Address": "106 Paseo de la Reforma",
    //         "Country": "Mexico",
    //         "State": "Mexico City",
    //         "City": "Mexico City",
    //         "PinCode": "06500"
    //     },
    //     {
    //         "_id": 11,
    //         "FirstName": "Amara",
    //         "LastName": "Okafor",
    //         "PhoneCode": "+234",
    //         "Phone": "8123456789",
    //         "Email": "amara.okafor@example.ng",
    //         "Company": "LagosEnterprises",
    //         "Password": "Nigeria2024!",
    //         "Address": "107 Victoria Island",
    //         "Country": "Nigeria",
    //         "State": "Lagos",
    //         "City": "Lagos",
    //         "PinCode": "101241"
    //     },
    //     {
    //         "_id": 12,
    //         "FirstName": "Hannah",
    //         "LastName": "Kim",
    //         "PhoneCode": "+82",
    //         "Phone": "1023456789",
    //         "Email": "hannah.kim@example.kr",
    //         "Company": "SeoulTech",
    //         "Password": "Korea2024!",
    //         "Address": "108 Gangnam Ave",
    //         "Country": "South Korea",
    //         "State": "Seoul",
    //         "City": "Seoul",
    //         "PinCode": "06034"
    //     },
    //     {
    //         "_id": 13,
    //         "FirstName": "Lars",
    //         "LastName": "Johansson",
    //         "PhoneCode": "+46",
    //         "Phone": "701234567",
    //         "Email": "lars.johansson@example.se",
    //         "Company": "StockholmInnovations",
    //         "Password": "Sweden2024!",
    //         "Address": "109 Drottninggatan",
    //         "Country": "Sweden",
    //         "State": "Stockholm",
    //         "City": "Stockholm",
    //         "PinCode": "111 22"
    //     },
    //     {
    //         "_id": 14,
    //         "FirstName": "Aisha",
    //         "LastName": "Mohamed",
    //         "PhoneCode": "+971",
    //         "Phone": "501234567",
    //         "Email": "aisha.mohamed@example.ae",
    //         "Company": "DubaiSolutions",
    //         "Password": "UAE2024!",
    //         "Address": "110 Sheikh Zayed Rd",
    //         "Country": "United Arab Emirates",
    //         "State": "Dubai",
    //         "City": "Dubai",
    //         "PinCode": "123456"
    //     },
    //     {
    //         "_id": 15,
    //         "FirstName": "Ravi",
    //         "LastName": "Patel",
    //         "PhoneCode": "+91",
    //         "Phone": "9876543210",
    //         "Email": "ravi.patel@example.in",
    //         "Company": "AhmedabadTech",
    //         "Password": "India2024!",
    //         "Address": "111 MG Road",
    //         "Country": "India",
    //         "State": "Gujarat",
    //         "City": "Ahmedabad",
    //         "PinCode": "380001"
    //     },
    //     {
    //         "_id": 16,
    //         "FirstName": "Emma",
    //         "LastName": "Brown",
    //         "PhoneCode": "+1",
    //         "Phone": "6172345678",
    //         "Email": "emma.brown@example.com",
    //         "Company": "BostonConsulting",
    //         "Password": "Boston2024!",
    //         "Address": "112 Beacon St",
    //         "Country": "USA",
    //         "State": "Massachusetts",
    //         "City": "Boston",
    //         "PinCode": "02108"
    //     },
    //     {
    //         "_id": 17,
    //         "FirstName": "Oliver",
    //         "LastName": "Wilson",
    //         "PhoneCode": "+64",
    //         "Phone": "212345678",
    //         "Email": "oliver.wilson@example.nz",
    //         "Company": "AucklandEnterprises",
    //         "Password": "NZ2024!",
    //         "Address": "113 Queen St",
    //         "Country": "New Zealand",
    //         "State": "Auckland",
    //         "City": "Auckland",
    //         "PinCode": "1010"
    //     },
    //     {
    //         "_id": 18,
    //         "FirstName": "Fatima",
    //         "LastName": "Ali",
    //         "PhoneCode": "+92",
    //         "Phone": "3112345678",
    //         "Email": "fatima.ali@example.pk",
    //         "Company": "KarachiIndustries",
    //         "Password": "Pakistan2024!",
    //         "Address": "114 Clifton Rd",
    //         "Country": "Pakistan",
    //         "State": "Sindh",
    //         "City": "Karachi",
    //         "PinCode": "75500"
    //     },
    //     {
    //         "_id": 19,
    //         "FirstName": "Juan",
    //         "LastName": "Perez",
    //         "PhoneCode": "+54",
    //         "Phone": "9112345678",
    //         "Email": "juan.perez@example.ar",
    //         "Company": "BuenosAiresTech",
    //         "Password": "Argentina2024!",
    //         "Address": "115 Avenida Corrientes",
    //         "Country": "Argentina",
    //         "State": "Buenos Aires",
    //         "City": "Buenos Aires",
    //         "PinCode": "1043"
    //     },
    //     {
    //         "_id": 20,
    //         "FirstName": "Sophia",
    //         "LastName": "Müller",
    //         "PhoneCode": "+49",
    //         "Phone": "15123456789",
    //         "Email": "sophia.muller@example.de",
    //         "Company": "BerlinInnovations",
    //         "Password": "Germany2024!",
    //         "Address": "116 Unter den Linden",
    //         "Country": "Germany",
    //         "State": "Berlin",
    //         "City": "Berlin",
    //         "PinCode": "10117"
    //     },
    //     {
    //         "_id": 21,
    //         "FirstName": "Isabella",
    //         "LastName": "Silva",
    //         "PhoneCode": "+55",
    //         "Phone": "2112345678",
    //         "Email": "isabella.silva@example.br",
    //         "Company": "RioEnterprises",
    //         "Password": "Brazil2024!",
    //         "Address": "117 Copacabana Ave",
    //         "Country": "Brazil",
    //         "State": "Rio de Janeiro",
    //         "City": "Rio de Janeiro",
    //         "PinCode": "22060-002"
    //     },
    //     {
    //         "_id": 22,
    //         "FirstName": "Noah",
    //         "LastName": "Novak",
    //         "PhoneCode": "+420",
    //         "Phone": "601234567",
    //         "Email": "noah.novak@example.cz",
    //         "Company": "PragueTech",
    //         "Password": "Czech2024!",
    //         "Address": "118 Charles Bridge St",
    //         "Country": "Czech Republic",
    //         "State": "Prague",
    //         "City": "Prague",
    //         "PinCode": "11000"
    //     },
    //     {
    //         "_id": 23,
    //         "FirstName": "Chloe",
    //         "LastName": "Lee",
    //         "PhoneCode": "+65",
    //         "Phone": "91234567",
    //         "Email": "chloe.lee@example.sg",
    //         "Company": "SingaporeSolutions",
    //         "Password": "SG2024!",
    //         "Address": "119 Orchard Rd",
    //         "Country": "Singapore",
    //         "State": "Singapore",
    //         "City": "Singapore",
    //         "PinCode": "238890"
    //     },
    //     {
    //         "_id": 24,
    //         "FirstName": "Kofi",
    //         "LastName": "Mensah",
    //         "PhoneCode": "+233",
    //         "Phone": "501234567",
    //         "Email": "kofi.mensah@example.gh",
    //         "Company": "AccraEnterprises",
    //         "Password": "Ghana2024!",
    //         "Address": "120 Independence Ave",
    //         "Country": "Ghana",
    //         "State": "Greater Accra",
    //         "City": "Accra",
    //         "PinCode": "00233"
    //     },
    //     {
    //         "_id": 25,
    //         "FirstName": "Yara",
    //         "LastName": "Hussein",
    //         "PhoneCode": "+20",
    //         "Phone": "1023456789",
    //         "Email": "yara.hussein@example.eg",
    //         "Company": "CairoTech",
    //         "Password": "Egypt2024!",
    //         "Address": "121 Tahrir Square",
    //         "Country": "Egypt",
    //         "State": "Cairo",
    //         "City": "Cairo",
    //         "PinCode": "11511"
    //     },
    //     {
    //         "_id": 26,
    //         "FirstName": "Ali",
    //         "LastName": "Khan",
    //         "PhoneCode": "+92",
    //         "Phone": "3123456789",
    //         "Email": "ali.khan@example.pk",
    //         "Company": "LahoreIndustries",
    //         "Password": "Pakistan2024!",
    //         "Address": "122 Liberty Market",
    //         "Country": "Pakistan",
    //         "State": "Punjab",
    //         "City": "Lahore",
    //         "PinCode": "54000"
    //     },
    //     {
    //         "_id": 27,
    //         "FirstName": "Sakura",
    //         "LastName": "Tanaka",
    //         "PhoneCode": "+81",
    //         "Phone": "9098765432",
    //         "Email": "sakura.tanaka@example.jp",
    //         "Company": "OsakaInnovations",
    //         "Password": "Japan2024!",
    //         "Address": "123 Namba St",
    //         "Country": "Japan",
    //         "State": "Osaka",
    //         "City": "Osaka",
    //         "PinCode": "542-0076"
    //     },
    //     {
    //         "_id": 28,
    //         "FirstName": "Mia",
    //         "LastName": "Nguyen",
    //         "PhoneCode": "+84",
    //         "Phone": "912345678",
    //         "Email": "mia.nguyen@example.vn",
    //         "Company": "HanoiTech",
    //         "Password": "Vietnam2024!",
    //         "Address": "124 Hoan Kiem",
    //         "Country": "Vietnam",
    //         "State": "Hanoi",
    //         "City": "Hanoi",
    //         "PinCode": "100000"
    //     },
    //     {
    //         "_id": 29,
    //         "FirstName": "Emily",
    //         "LastName": "Wilson",
    //         "PhoneCode": "+1",
    //         "Phone": "3102345678",
    //         "Email": "emily.wilson@example.com",
    //         "Company": "LAConsulting",
    //         "Password": "LA2024!",
    //         "Address": "125 Sunset Blvd",
    //         "Country": "USA",
    //         "State": "California",
    //         "City": "Los Angeles",
    //         "PinCode": "90028"
    //     },
    //     {
    //         "_id": 30,
    //         "FirstName": "Aditya",
    //         "LastName": "Sharma",
    //         "PhoneCode": "+91",
    //         "Phone": "9123456789",
    //         "Email": "aditya.sharma@example.in",
    //         "Company": "DelhiSolutions",
    //         "Password": "India2024!",
    //         "Address": "126 Connaught Place",
    //         "Country": "India",
    //         "State": "Delhi",
    //         "City": "New Delhi",
    //         "PinCode": "110001"
    //     },
    //     {
    //         "_id": 31,
    //         "FirstName": "Nia",
    //         "LastName": "Johnson",
    //         "PhoneCode": "+27",
    //         "Phone": "7912345678",
    //         "Email": "nia.johnson@example.za",
    //         "Company": "CapeTownEnterprises",
    //         "Password": "SA2024!",
    //         "Address": "127 Long St",
    //         "Country": "South Africa",
    //         "State": "Western Cape",
    //         "City": "Cape Town",
    //         "PinCode": "8001"
    //     },
    //     {
    //         "_id": 32,
    //         "FirstName": "Liam",
    //         "LastName": "Evans",
    //         "PhoneCode": "+61",
    //         "Phone": "412345678",
    //         "Email": "liam.evans@example.au",
    //         "Company": "SydneyInnovations",
    //         "Password": "Australia2024!",
    //         "Address": "128 George St",
    //         "Country": "Australia",
    //         "State": "New South Wales",
    //         "City": "Sydney",
    //         "PinCode": "2000"
    //     },
    //     {
    //         "_id": 33,
    //         "FirstName": "Fatou",
    //         "LastName": "Diop",
    //         "PhoneCode": "+221",
    //         "Phone": "771234567",
    //         "Email": "fatou.diop@example.sn",
    //         "Company": "DakarTech",
    //         "Password": "Senegal2024!",
    //         "Address": "129 Independence Blvd",
    //         "Country": "Senegal",
    //         "State": "Dakar",
    //         "City": "Dakar",
    //         "PinCode": "12345"
    //     },
    //     {
    //         "_id": 34,
    //         "FirstName": "Ivan",
    //         "LastName": "Petrov",
    //         "PhoneCode": "+7",
    //         "Phone": "9212345678",
    //         "Email": "ivan.petrov@example.ru",
    //         "Company": "SaintPetersburgIndustries",
    //         "Password": "Russia2024!",
    //         "Address": "130 Nevsky Prospect",
    //         "Country": "Russia",
    //         "State": "Saint Petersburg",
    //         "City": "Saint Petersburg",
    //         "PinCode": "191025"
    //     },
    //     {
    //         "_id": 35,
    //         "FirstName": "Sara",
    //         "LastName": "Andersson",
    //         "PhoneCode": "+46",
    //         "Phone": "701234567",
    //         "Email": "sara.andersson@example.se",
    //         "Company": "GothenburgSolutions",
    //         "Password": "Sweden2024!",
    //         "Address": "131 Kungsportsavenyn",
    //         "Country": "Sweden",
    //         "State": "Västra Götaland",
    //         "City": "Gothenburg",
    //         "PinCode": "411 36"
    //     },
    //     {
    //         "_id": 36,
    //         "FirstName": "Ahmed",
    //         "LastName": "Youssef",
    //         "PhoneCode": "+20",
    //         "Phone": "1123456789",
    //         "Email": "ahmed.youssef@example.eg",
    //         "Company": "AlexandriaInnovations",
    //         "Password": "Egypt2024!",
    //         "Address": "132 Corniche",
    //         "Country": "Egypt",
    //         "State": "Alexandria",
    //         "City": "Alexandria",
    //         "PinCode": "21500"
    //     },
    //     {
    //         "_id": 37,
    //         "FirstName": "Camila",
    //         "LastName": "Torres",
    //         "PhoneCode": "+57",
    //         "Phone": "3112345678",
    //         "Email": "camila.torres@example.co",
    //         "Company": "BogotaTech",
    //         "Password": "Colombia2024!",
    //         "Address": "133 Carrera 7",
    //         "Country": "Colombia",
    //         "State": "Bogota",
    //         "City": "Bogota",
    //         "PinCode": "110231"
    //     },
    //     {
    //         "_id": 38,
    //         "FirstName": "Jorge",
    //         "LastName": "Gonzalez",
    //         "PhoneCode": "+34",
    //         "Phone": "612345678",
    //         "Email": "jorge.gonzalez@example.es",
    //         "Company": "BarcelonaInnovations",
    //         "Password": "Spain2024!",
    //         "Address": "134 La Rambla",
    //         "Country": "Spain",
    //         "State": "Catalonia",
    //         "City": "Barcelona",
    //         "PinCode": "08002"
    //     },
    //     {
    //         "_id": 39,
    //         "FirstName": "Hana",
    //         "LastName": "Nováková",
    //         "PhoneCode": "+420",
    //         "Phone": "602345678",
    //         "Email": "hana.novakova@example.cz",
    //         "Company": "BrnoSolutions",
    //         "Password": "Czech2024!",
    //         "Address": "135 Freedom Square",
    //         "Country": "Czech Republic",
    //         "State": "South Moravian",
    //         "City": "Brno",
    //         "PinCode": "60200"
    //     },
    //     {
    //         "_id": 40,
    //         "FirstName": "Sofia",
    //         "LastName": "Fernandez",
    //         "PhoneCode": "+54",
    //         "Phone": "9112345678",
    //         "Email": "sofia.fernandez@example.ar",
    //         "Company": "CordobaTech",
    //         "Password": "Argentina2024!",
    //         "Address": "136 San Martin",
    //         "Country": "Argentina",
    //         "State": "Cordoba",
    //         "City": "Cordoba",
    //         "PinCode": "5000"
    //     },
    //     {
    //         "_id": 41,
    //         "FirstName": "Luca",
    //         "LastName": "Bianchi",
    //         "PhoneCode": "+39",
    //         "Phone": "3456789012",
    //         "Email": "luca.bianchi@example.it",
    //         "Company": "FlorenceSolutions",
    //         "Password": "Italy2024!",
    //         "Address": "137 Ponte Vecchio",
    //         "Country": "Italy",
    //         "State": "Tuscany",
    //         "City": "Florence",
    //         "PinCode": "50125"
    //     },
    //     {
    //         "_id": 42,
    //         "FirstName": "Aria",
    //         "LastName": "Jensen",
    //         "PhoneCode": "+45",
    //         "Phone": "612345678",
    //         "Email": "aria.jensen@example.dk",
    //         "Company": "CopenhagenInnovations",
    //         "Password": "Denmark2024!",
    //         "Address": "138 Nyhavn",
    //         "Country": "Denmark",
    //         "State": "Capital Region",
    //         "City": "Copenhagen",
    //         "PinCode": "1051"
    //     },
    //     {
    //         "_id": 43,
    //         "FirstName": "Mateo",
    //         "LastName": "Rodriguez",
    //         "PhoneCode": "+34",
    //         "Phone": "612345678",
    //         "Email": "mateo.rodriguez@example.es",
    //         "Company": "SevilleTech",
    //         "Password": "Spain2024!",
    //         "Address": "139 Plaza de España",
    //         "Country": "Spain",
    //         "State": "Andalusia",
    //         "City": "Seville",
    //         "PinCode": "41013"
    //     },
    //     {
    //         "_id": 44,
    //         "FirstName": "Nour",
    //         "LastName": "Fahmy",
    //         "PhoneCode": "+20",
    //         "Phone": "1123456789",
    //         "Email": "nour.fahmy@example.eg",
    //         "Company": "GizaIndustries",
    //         "Password": "Egypt2024!",
    //         "Address": "140 Pyramids Road",
    //         "Country": "Egypt",
    //         "State": "Giza",
    //         "City": "Giza",
    //         "PinCode": "12511"
    //     },
    //     {
    //         "_id": 45,
    //         "FirstName": "Haruto",
    //         "LastName": "Yamamoto",
    //         "PhoneCode": "+81",
    //         "Phone": "9098765432",
    //         "Email": "haruto.yamamoto@example.jp",
    //         "Company": "NagoyaSolutions",
    //         "Password": "Japan2024!",
    //         "Address": "141 Nagoya Castle",
    //         "Country": "Japan",
    //         "State": "Aichi",
    //         "City": "Nagoya",
    //         "PinCode": "460-0001"
    //     },
    //     {
    //         "_id": 46,
    //         "FirstName": "Ava",
    //         "LastName": "Clark",
    //         "PhoneCode": "+1",
    //         "Phone": "2123456789",
    //         "Email": "ava.clark@example.com",
    //         "Company": "NYCConsulting",
    //         "Password": "NY2024!",
    //         "Address": "142 Wall St",
    //         "Country": "USA",
    //         "State": "New York",
    //         "City": "New York",
    //         "PinCode": "10005"
    //     },
    //     {
    //         "_id": 47,
    //         "FirstName": "Omar",
    //         "LastName": "Ahmed",
    //         "PhoneCode": "+20",
    //         "Phone": "1123456789",
    //         "Email": "omar.ahmed@example.eg",
    //         "Company": "LuxorTech",
    //         "Password": "Egypt2024!",
    //         "Address": "143 Luxor Temple",
    //         "Country": "Egypt",
    //         "State": "Luxor",
    //         "City": "Luxor",
    //         "PinCode": "85951"
    //     },
    //     {
    //         "_id": 48,
    //         "FirstName": "Lucas",
    //         "LastName": "Martins",
    //         "PhoneCode": "+55",
    //         "Phone": "2112345678",
    //         "Email": "lucas.martins@example.br",
    //         "Company": "SaoPauloEnterprises",
    //         "Password": "Brazil2024!",
    //         "Address": "144 Paulista Ave",
    //         "Country": "Brazil",
    //         "State": "Sao Paulo",
    //         "City": "Sao Paulo",
    //         "PinCode": "01311-200"
    //     },
    //     {
    //         "_id": 49,
    //         "FirstName": "Emma",
    //         "LastName": "Smith",
    //         "PhoneCode": "+61",
    //         "Phone": "412345678",
    //         "Email": "emma.smith@example.au",
    //         "Company": "MelbourneInnovations",
    //         "Password": "Australia2024!",
    //         "Address": "145 Collins St",
    //         "Country": "Australia",
    //         "State": "Victoria",
    //         "City": "Melbourne",
    //         "PinCode": "3000"
    //     },
    //     {
    //         "_id": 50,
    //         "FirstName": "Sophia",
    //         "LastName": "Brown",
    //         "PhoneCode": "+1",
    //         "Phone": "4152345678",
    //         "Email": "sophia.brown@example.com",
    //         "Company": "SanFranciscoSolutions",
    //         "Password": "SF2024!",
    //         "Address": "146 Market St",
    //         "Country": "USA",
    //         "State": "California",
    //         "City": "San Francisco",
    //         "PinCode": "94103"
    //     }
    // ]  
    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [btndisable, setBtndisable] = useState(true);
    const totalPages = Math.ceil(regData.length / itemsPerPage);
    const handleFirst = () => setCurrentPage(1);
    const handlePrev = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNext = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handleLast = () => setCurrentPage(totalPages);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const currentPageData = regData.slice(startIdx, startIdx + itemsPerPage);
    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-registrations`);
                setRegData(res.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        
        $("#selectAll").on("change", function () {
            const isChecked = $(this).prop("checked");
            $(".admin-table tbody input[type=checkbox]").prop("checked", isChecked);
            setBtndisable(!isChecked);
        });
        $(".admin-table tbody input[type=checkbox]").on("change", function () {
            if (!$(this).prop("checked")) {
                $("#selectAll").prop("checked", false);
                if (!$(".admin-table tbody input[type=checkbox]").is(":checked")) {
                    setBtndisable(true);
                }
            } else {
                setBtndisable(false);
            }
        });
        var pageh = $(window).height();
        var ex = 65 + 49 + 100 + 38 + 16;
        var total = pageh - ex;
        $(".scroll").height(total + "px");
    }, []);
    const [delId, setDelId] = useState("");
    const handleDelete = async (id) => {
        try {
            await axios.get(`${backendUrl}/admin/delete-reg/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <AdminLayout>
            <AdminBreadCrumb title="Registrations"/>
            <div className="main-layout">
                <div className="container-fluid">
                    {
                        regData.length !== 0 ? 
                        <>
                        <div className="table-responsive scroll">
                            <table className="table table-hover table-striped admin-table registration-table">
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <input type="checkbox" id="selectAll"/>
                                                <div class="dropdown">
                                                    <button class="btn p-0" style={{ position: "relative", top: "2px", color: "#fff", border: "none" }} type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={btndisable ? true : false}>
                                                        <i className='bx bx-chevron-down' ></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li><button class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#bulkdeleteModal">Bulk Delete</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </th>
                                        <th>Actions</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Password</th>
                                        <th>Company</th>
                                        <th>Address</th>
                                        <th>Country</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentPageData.map((el, index) => {
                                            return (
                                                <tr key={el.ID}>
                                                    <td>{startIdx + index + 1}</td>
                                                    <td><input type="checkbox" id={`user${el.ID}`}/></td>
                                                    <td>
                                                        <div className="d-flex gap-1">
                                                            <button className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#deleteRegistrationModal" onClick={() => setDelId(el.ID)}><i className='bx bxs-trash'></i></button>
                                                        </div>
                                                    </td>
                                                    <td>{el.ID}</td>
                                                    <td>{el.FirstName} {el.LastName}</td>
                                                    <td>{el.Email}</td>
                                                    <td>{el.PhoneCode} {el.Phone}</td>
                                                    <td>{el.Password}</td>
                                                    <td>{el.Company}</td>
                                                    <td>{el.Address}</td>
                                                    <td>{el.Country}</td>
                                                    <td>{el.State}</td>
                                                    <td>{el.City}</td>
                                                    <td>{el.PinCode}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            regData.length !== 0 ? 
                            <nav aria-label="...">
                            <ul class="pagination mb-0 mt-3 justify-content-end">
                                {
                                    currentPage > 1 && (
                                        <>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleFirst}>First</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handlePrev}>Previous</button>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    Array.from({ length: totalPages }, (_, index) => (
                                        <li class={`page-item ${currentPage === index + 1 ? "active": ""}`} key={index}>
                                            <button class="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                        </li>
                                    ))
                                }
                                {
                                    currentPage < totalPages && (
                                        <>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleNext}>Next</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleLast}>Last</button>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </nav> : ""
                        }
                        </>
                    :
                    <p className="mb-0">No Results Found!</p>
                    }
                </div>
            </div>
            <div class="modal fade text-black" id="deleteRegistrationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Delete User</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to delete the user ?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" style={{ width: "50%" }} onClick={() => handleDelete(delId)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="bulkdeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Bulk Delete Users</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to bulk delete the users ?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" style={{ width: "50%" }}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Registrations;