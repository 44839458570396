import { useEffect } from "react";
import Layout from "../components/Layout"
import ProfileCardHeader from "../components/ProfileCardHeader";
import UserProfileCommonPanel from "../components/UserProfileCommonPanel";
import "./Userprofile.css";
import Cookies from "js-cookie";
const UserProfile = () => {
    const user = JSON.parse(Cookies.get("user"));
    useEffect(() => {
        document.title = `${user.fname} ${user.lname} | Profile`;
        return () => {
            document.title = "React App"
        }
    }, [])
    return (
        <Layout>
            <div className="container">
                <div className="row userprofile_layout">
                    <UserProfileCommonPanel user={user}/>
                    <div className="col-md-9">
                        <div className="card border-0">
                            <ProfileCardHeader title="User Profile"/>
                            <div className="card-body">
                                <div className="h5">Personal Details:-</div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Name:</p>
                                            <p>{JSON.parse(Cookies.get("user")).fname} {JSON.parse(Cookies.get("user")).lname}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Email:</p>
                                            <p>{JSON.parse(Cookies.get("user")).email}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Phone:</p>
                                            <p>{JSON.parse(Cookies.get("user")).phone_code} {JSON.parse(Cookies.get("user")).phone}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Company:</p>
                                            <p>{JSON.parse(Cookies.get("user")).company}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Address:</p>
                                            <p>{JSON.parse(Cookies.get("user")).address}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">Country:</p>
                                            <p>{JSON.parse(Cookies.get("user")).country}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">State:</p>
                                            <p>{JSON.parse(Cookies.get("user")).state}</p>
                                        </div>
                                        <div className="mb-2">
                                            <p className="text-muted mb-0">City:</p>
                                            <p>{JSON.parse(Cookies.get("user")).city}</p>
                                        </div>
                                        <div className="">
                                            <p className="text-muted mb-0">Pin:</p>
                                            <p>{JSON.parse(Cookies.get("user")).pin}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserProfile;