import { Link, useNavigate } from 'react-router-dom';
import './Custom-fonts.css';
import './Layout-custom.css';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import HeaderLogo from '../assets/logo.png';
import Profile from '../assets/user.png';
import { backendUrl, imageUrl } from '../data/Url';
const Layout = ({ children }) => {
    const [fixed, setFixed] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [toogle, setToogle] = useState(false);
    const [imageSrc, setImageSrc] = useState(Profile);
    useEffect(() => {
        const fetchImage = async () => {
            try {
                const userID = JSON.parse(Cookies.get("user")).userid;
                const res = await axios.get(`${backendUrl}/fetch-user-image/${userID}`);
                console.log(res.data);
                const status = res.data.Status;
                if (status === 200) {
                    let currentDate = new Date();
                    let dt = currentDate.getDate() + currentDate.getTime();
                    if (res.data.ProfileImage !== null) {
                        setImageSrc(`${imageUrl}${res.data.ProfileImage}?v=${dt}`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchImage();
        window.addEventListener("scroll", () => {
            if (window.scrollY > 60) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        })
    }, [])
    const navigate = useNavigate();
    const handleLogout = (e) => {
        e.preventDefault();
        Cookies.remove("user");
        localStorage.removeItem("newcard");
        navigate("/");
    }
    const handleMenuShow = () => {
        setShowMenu(!showMenu);
        setToogle(!toogle);
    }
    const handleCloseMenu = () => {
        setShowMenu(false);
        setToogle(false);
    }
    const [newsletterEmail, setNewsletterEmail] = useState("");
    const [userId, setUserId] = useState("0");
    useEffect(() => {
        if (Cookies.get("user")) {
            setUserId(JSON.parse(Cookies.get("user")).userid);
        }
    }, [])
    const handleNewsletter = async () => {
        try {
            const res = await axios.post(`${backendUrl}/save-newsletter`, {
                Email: newsletterEmail,
                RefUserID: userId
            });
            const status = res.data.Status;
            const message = res.data.Message;
            if (status === 200) {
                toast.success(message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => setNewsletterEmail("")
                })
            } else if (status === 500) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
        <header className={`header-layout ${fixed ? "fixed" : ""}`}>
            <div className="container">
                <nav className='navbar-layout'>
                    <Link to="/" className='nav-brand'><img src={HeaderLogo} alt="digitalbusinesscard"/></Link>
                    <div className={`nav-content ${showMenu ? "show" : ""}`}>
                        <ul className='nav-menu'>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/pricing">Pricing</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/create-card" className='createcard_btn'>Create Your Digital Business Card</Link></li>
                        </ul>
                        {
                            Cookies.get("user") ? <div className="accordion mt-3" id="accordionProfile">
                            <div className="accordion-item border-0">
                                <button className="profile_dropdown collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <img src={imageSrc} alt='profile' className='profile-image'/> {JSON.parse(Cookies.get("user")).fname} {JSON.parse(Cookies.get("user")).lname}
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body p-0 mt-3">
                                        <ul className='dropdown-menu' style={{ display: "block", position: "relative" }}>
                                            <li><Link to="/user-profile" className='dropdown-item'><i className='bx bx-user-circle'></i> Profile</Link></li>
                                            <li><Link to="/user-profile-update" className='dropdown-item'><i className="bx bx-upload"></i> Update Profile</Link></li>
                                            <li><Link to="/user-profile-card-management" className='dropdown-item'><i className="bx bxs-id-card"></i> Card Management</Link></li>
                                            {/* <li><Link to="/user-profile-feedback" className='dropdown-item'><i className="bx bx-message-dots"></i> Feedbacks</Link></li>
                                            <li><Link to="/user-profile-contact" className='dropdown-item'><i className="bx bxs-contact"></i> Contacts</Link></li>
                                            <li><button className='dropdown-item' type="button" data-bs-toggle="modal" data-bs-target="#changepasswordModal"><i className='bx bxs-lock-alt'></i> Change Password</button></li> */}
                                            <li><button className="dropdown-item" onClick={(e) => handleLogout(e)}><i className='bx bx-log-out'></i> Logout</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : 
                        <Link className='signup_btn' to="/login">Sign In <i className='bx bx-right-arrow-alt'></i></Link>
                        }
                    </div>
                    {
                        Cookies.get("user") ? 
                            <div className="dropdown profile_dropdown">
                                <button className="btn btn-default text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={imageSrc} alt='profile' className='profile-image'/> {JSON.parse(Cookies.get("user")).fname} {JSON.parse(Cookies.get("user")).lname}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><Link to="/user-profile" className='dropdown-item'><i className='bx bx-user-circle'></i> Profile</Link></li>
                                    {/* <li><button className='dropdown-item' type="button" data-bs-toggle="modal" data-bs-target="#changepasswordModal"><i className='bx bxs-lock-alt'></i> Change Password</button></li> */}
                                    <li><button className="dropdown-item" onClick={(e) => handleLogout(e)}><i className='bx bx-log-out'></i> Logout</button></li>
                                </ul>
                            </div>
                        : 
                         <Link className='signup_btn' to="/login">Sign In <i className='bx bx-right-arrow-alt'></i></Link>
                    }
                    <button className={`hamburger ${toogle ? "toogled" : ""}`} onClick={handleMenuShow}>
                        <p className='d-none'>ham</p>
                        <span className='bar'></span>
                        <span className='bar'></span>
                        <span className='bar'></span>
                    </button>
                </nav>
            </div>
        </header>
        <div className={`nav-overlay ${showMenu ? "show" : ""}`} onClick={handleCloseMenu}></div>
        { children }
        <footer className='footer-layout'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <span className='footer-brand'><img src={HeaderLogo} alt="digitalbusinesscard"/></span>
                        <p className='mb-4'>Top choice for professional digital cards.Discover the ultimate hub for digital business cards. Unlock unparalleled networking potential with the premier digital business card platform.</p>
                        <ul className='social-layout'>
                            <li><Link to="#"><i className='bx bxl-facebook'></i></Link></li>
                            <li><Link to="#"><i className='bx bxl-twitter'></i></Link></li>
                            <li><Link to="#"><i className='bx bxl-instagram'></i></Link></li>
                            <li><Link to="#"><i className='bx bxl-linkedin'></i></Link></li>
                        </ul>
                    </div>
                    <div className='col-md-4'>
                        <div className='footer_box'>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/create-card">Create Your Digital Business Card</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='footer_box' style={{ paddingLeft: "0px" }}>
                            <h5>Join Our Newsletter</h5>
                            <input type="email" className="form-input" placeholder='Email' value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)}/>
                            <button className='form-submit' onClick={() => handleNewsletter()}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="modal fade" id="changepasswordModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5 text-black" id="exampleModalLabel">Change Password</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='mb-2'>
                            <input className='form-control' placeholder='Current Password'/>
                        </div>
                        <div>
                            <input className='form-control' placeholder='New Password'/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger">Change Password</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Layout;