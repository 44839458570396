import { useEffect } from "react";
import { Link } from "react-router-dom";

const SideCart = ({ data, show, toogle }) => {
    useEffect(() => {
        import("./sidecart.css");
    }, [])
    return (
        <div className={`sidecart-layout ${show ? "show" : ""}`}>
            <div className="sidecart-header">
                <div className="d-flex align-items-center gap-1">
                    Cart <i className='bx bxs-cart-alt'></i>
                </div>
                <div>
                    <button className="cart-close" onClick={() => toogle(false)}><i className='bx bx-x-circle'></i></button>
                </div>
            </div>
            <div className="sidecart-body">
                {
                    data.length !== 0 ? "" 
                    : <p>Cart is empty!</p>
                }
            </div>
            <div className="sidecart-footer">
                <Link to="/checkout" className="primary-btn" style={{ width: "100%", justifyContent: "center" }}>Checkout</Link>
            </div>
        </div>
    )
}

export default SideCart;