import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import { useEffect, useRef, useState } from 'react';
import phoneCodes from '../data/phonecode.json';
import Countries from '../data/Country.json';
import Cities from '../data/city.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import { backendUrl } from '../data/Url';
const Signup = () => {
    const navigate = useNavigate();
    const selectRef = useRef(null);
    const selectRef2 = useRef(null);
    const selectRef3 = useRef(null);
    const selectRef4 = useRef(null);
    const [options, setOptions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [country, setcountry] = useState('India');
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [address, setAddress] = useState("");

    const [showFNameError, setShowFNameError] = useState(false);
    const [showLNameError, setShowLNameError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showCompanyError, setCompanyError] = useState(false);
    const [showPasswordError, setPasswordError] = useState(false);
    const [showCountryError, setCountryError] = useState(false);
    const [showStateError, setStateError] = useState(false);
    const [showCityError, setCityError] = useState(false);
    const [showPincodeError, setPincodeError] = useState(false);
    const [passwordRegexIssue, setPasswordRegexIssue] = useState(false);
    const [addressError, setAddressError] = useState(false);
    

    useEffect(() => {
        import("./Login.css");
        setOptions(phoneCodes);
        $(selectRef.current).select2({
            width: "100%",
            dropdownParent: $(".phone-country-select")
        });

        const phone_Code = $("#phone_code").val();
        setPhoneCode(phone_Code);

        return () => {
            $(selectRef.current).select2('destroy')
        }
    })


    useEffect(() => {
        setCountries(Countries);

        $(selectRef2.current).select2({ width: '100%', dropdownParent: $(".country_drop") });
        $(selectRef3.current).select2({ width: '100%', dropdownParent: $(".state_drop") });
        $(selectRef4.current).select2({ width: '100%', dropdownParent: $(".city_drop") });

        $("#select_country").on("change", function (e) {
            const selectedCountry = e.target.value;
            setcountry(selectedCountry);
            const statesData = countries.find(c => c.name.toLowerCase() === selectedCountry.toLowerCase());
            if (statesData) {
                setStates(statesData.states);
            } else {
                setStates([]);
            }
        })
        $(".state_drop select").on("change", function (e) {
            const selectedState = e.target.value;
            setState(selectedState);
            const citiesData = Cities.find(c => c.name.toLowerCase() === selectedState.toLowerCase());
            if (citiesData) {
                setCities(citiesData.cities);
            } else {
                setCities([]);
            }
        })

        $("#select_city").on("change", function (e) {
            const selectedCity = e.target.value;
            setCity(selectedCity);
        })

        return () => {
            $(selectRef2.current).select2('destroy');
            $(selectRef3.current).select2('destroy');
            $(selectRef4.current).select2('destroy');
        };
    }, [Countries, Cities]);

    useEffect(() => {
        const statesData = countries.find(c => c.name.toLowerCase() === country.toLowerCase());
        if (statesData) {
            setStates(statesData.states);
        } else {
            setStates([]);
        }
    }, [country, countries]);

    useEffect(() => {
        if (selectRef3.current) {
            $(selectRef3.current).select2('destroy').select2({ width: '100%', dropdownParent: $(".state_drop") });
        }
    }, [states]);

    useEffect(() => {
        if (selectRef4.current) {
            $(selectRef4.current).select2('destroy').select2({ width: '100%', dropdownParent: $(".city_drop") });
        }
    }, [cities]);


    const handleSignup = async (e) => {
        try {
            e.preventDefault();
            var valid = true;
            const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (fname.length == 0) {
                setShowFNameError(true);
                valid = false;
            } else {
                setShowFNameError(false);
            }
            if (lname.length == 0) {
                setShowLNameError(true);
                valid = false;
            } else {
                setShowLNameError(false);
            }
            if (phone.length == 0) {
                setShowPhoneError(true);
                valid = false;
            } else {
                setShowPhoneError(false);
            }
            if (email.length == 0) {
                setShowEmailError(true);
                valid = false;
            } else {
                setShowEmailError(false);
            }
            if (company.length == 0) {
                setCompanyError(true);
                valid = false;
            } else {
                setCompanyError(false);
            }
            if (password.length == 0) {
                setPasswordError(true);
                valid = false;
            } else {
                if (!regex.test(password)) {
                    setPasswordRegexIssue(true);
                } else {
                    setPasswordRegexIssue(false);
                }
                setPasswordError(false);
            }
            if (country.length == 0) {
                setCountryError(true);
                valid = false
            } else {
                setCountryError(false);
            }
            if (state.length == 0) {
                setStateError(true);
                valid = false
            } else {
                setStateError(false)
            }
            if (city.length == 0) {
                setCityError(true);
                valid = false
            } else {
                setCityError(false)
            }
            if (pincode.length == 0) {
                setPincodeError(true);
                valid = false
            } else {
                setPincodeError(false);
            }
            if (address.length == 0) {
                setAddressError(true);
                valid = false
            } else {
                setAddressError(false);
            }

            if (valid) {
                const newUser = {
                    FirstName: fname,
                    LastName: lname,
                    PhoneCode: phoneCode,
                    Phone: phone,
                    Email: email,
                    Company: company,
                    Password: password,
                    Address: address,
                    Country: country,
                    State: state,
                    City: city,
                    PinCode: pincode
                };
                const res = await axios.post(`${backendUrl}/save-registration`, newUser, {
                    headers: { 
                        "Content-type": "application/json; charset=UTF-8" , "Access-Control-Allow-Origin" : "*"
                    }
                })
                const dt = res.data;
                const status = dt.Status;
                
                var toastOptions = {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate("/login")
                }
                var toastOptions2 = {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                }
                if (status === 200) {
                    toast.success(dt.Message, toastOptions);
                } else if (status === 400) {
                    toast.warning(dt.Message, toastOptions2);
                } else if (status === 500) {
                    toast.error(dt.Message, toastOptions2);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='form-wrapper-layout'>
            <div className="row m-0">
                <div className="col-md-8 p-0">
                    <div className="login-leftbox">
                        <img src="assets/images/signuppge-image.webp" alt=""/>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="login-rightbox">
                        <div style={{width: "100%"}}>
                            <h1 className='mb-4'>Signup</h1>
                            <form onSubmit={handleSignup}>
                            <div className='mb-2'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <input id="fname" placeholder='First Name' type="text" onChange={(e) => setFname(e.target.value)}/>
                                            {showFNameError && <p className='text-danger mb-0'>First Name is required !</p>}
                                        </div>
                                        <div className='col-6'>
                                            <input id="lname" placeholder='Last Name' type="text" onChange={(e) => setLname(e.target.value)}/>
                                            {showLNameError && <p className='text-danger mb-0'>Last Name is required !</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className='row m-0'>
                                        <div className='col-4 p-0 phone-country-select'>
                                            <select ref={selectRef} id="phone_code">
                                                {
                                                    options.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.dial_code} selected={el.dial_code === "+91"}>
                                                                {el.dial_code} ({el.name})
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='col-8 p-0'>
                                            <input type='tel' id='phone' placeholder='Phone' onChange={(e) => setPhone(e.target.value)}/>
                                        </div>
                                    </div>
                                    {showPhoneError && <p className='text-danger mb-0'>Phone Number is required !</p>}
                                </div>
                                <div className="mb-2">
                                    <input id="email" placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)}/>
                                    {showEmailError && <p className='text-danger mb-0'>Email is required !</p>}
                                </div>
                                <div className='mb-2'>
                                    <input type='text' placeholder='Company Name' id="companyname" onChange={(e) => setCompany(e.target.value)}/>
                                    {showCompanyError && <p className='text-danger mb-0'>Company Name is required !</p>}
                                </div>
                                <div className="mb-2">
                                    <input id="password" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}/>
                                    {showPasswordError && <p className='text-danger mb-0'>Password is required !</p>}
                                    {passwordRegexIssue && <p className='text-danger mb-0'>Password must have at least 8 characters, at least one uppercase letter & at least one digit</p>}
                                </div>
                                <div className='mb-2'>
                                    <input placeholder='Address' type="text" onChange={(e) => setAddress(e.target.value)}/>
                                    {addressError && <p className='text-danger mb-0'>Address is required !</p>}
                                </div>
                                <div className='row '>
                                    <div className="col-md-6 mb-2">
                                        <div className="country_drop">
                                            <select ref={selectRef2} id="select_country">
                                                <option>-- Country --</option>
                                                {
                                                    countries.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name} selected={el.name === country}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {showCountryError && <p className='text-danger mb-0'>Country is required !</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="state_drop">
                                            <select ref={selectRef3} id="select_state">
                                                <option>-- State --</option>
                                                {
                                                    states.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {showStateError && <p className='text-danger mb-0'>State is required !</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="city_drop">
                                            <select ref={selectRef4} id="select_city">
                                                <option>-- City --</option>
                                                {
                                                    cities.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {showCityError && <p className='text-danger mb-0'>City is required !</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <input type="text" className="form-control" placeholder="Pincode" onChange={(e) => setPincode(e.target.value)}/>
                                        {showPincodeError && <p className='text-danger mb-0'>Pincode is required !</p>}
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-main w-100 mb-3" type="submit">Signup</button>
                            </form>
                            <p>Already have an account, <Link to="/login" className='form-link'>Login Now</Link></p>
                            {/* <div className='h6'>Or else, Signup using</div> */}
                            {/* <ul className='social-login mt-3'>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-facebook'></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className="fa-brands fa-x-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-instagram'></i></Link>
                                </li>
                                <li>
                                    <Link className="btn-social-login"><i className='bx bxl-linkedin' ></i></Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup;