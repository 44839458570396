import { useNavigate, useParams } from "react-router-dom";
import DigitalCardLayout from "../components/DigitalCardLayout";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Page404 from "./Phe404";
import { backendUrl, imageUrl } from "../data/Url";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';
const DynamicCard = () => {
    const { id } = useParams();
    const qrRef = useRef();
    const navigate = useNavigate();
    const [filteredCardData, setFilteredCardData] = useState({});
    const [controls, setControls] = useState(false);
    const [error404, setError404] = useState(false);
    const [wpNumber, setWpNumber] = useState("+91 ");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/card-preview/${id}`);
                console.log(res.data.data);
                setFilteredCardData(res.data.data);
                if (res.data.data1.isPublished === false) {
                    setError404(true);
                    //navigate("/*");
                }
                if (Cookies.get("user")) {
                    if (JSON.parse(Cookies.get("user")).userid === res.data.data1.UserID) {
                        setControls(true);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, []);
    useEffect(() => {
        if (filteredCardData && filteredCardData.ThemeCss && !error404) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${imageUrl}${filteredCardData.ThemeCss}`;
            link.onload = () => console.log(`CSS file loaded`);
            document.head.appendChild(link);

            import("./Preview.css");

            return () => {
                document.head.removeChild(link);
            }
        }
    }, [filteredCardData])
    const [menuShow, setMenuShow] = useState(false);
    const menuToogler = () => {
        setMenuShow(!menuShow);
    }
    const handleUnPublish = async () => {
        try {
            const res = await axios.post(`${backendUrl}/unpublish-card/${id}`);
            const status = res.data.Status;
            if (status === 200) {
                toast.success("Your card is successfully unpublished", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => navigate("/user-profile-card-management")
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handlecopyUrl = () => {
        const text = window.location.href;
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Link Copied!")
        }).catch(err => {
            console.error('Failed to copy text: ', err);
          });
    }
    const handleChange = (e) => {
        let input = e.target.value;
        if (input.length > 3) {
            setWpNumber(input);
        }
      };
    const handlesendWhatsapp = () => {
        const text = window.location.href;
        const number = wpNumber.replace("+", "").replace(" ", "");
        var api = `https://wa.me/${number}?text=${text}`;
        window.open(api);
        setWpNumber("+91 ");
    }
    const handleShare = async () => {
        try {
          const dataUrl = await toPng(qrRef.current);
    
          if (navigator.share) {
            await navigator.share({
              title: 'Check this out!',
              text: 'Here is something interesting for you.',
              files: [new File([dataUrl], 'qrcode.png', { type: 'image/png' })],
            });
            console.log('Content shared successfully');
          } else {
            alert('Web Share API is not supported in your browser.');
          }
        } catch (error) {
          //console.error('Error sharing content:', error);
        }
      };
      const handleCopy = async () => {
        try {
          const dataUrl = await toPng(qrRef.current);
          const blob = await (await fetch(dataUrl)).blob();
          const clipboardItem = new ClipboardItem({ 'image/png': blob });
    
          await navigator.clipboard.write([clipboardItem]);
          toast.success("QR Code copied to clipboard");
        } catch (error) {
          console.error('Error copying content:', error);
          //alert('Failed to copy the QR Code');
        }
      };
    
      const handleDownload = () => {
        if (qrRef.current) {
          html2canvas(qrRef.current)
            .then((canvas) => {
              const link = document.createElement('a');
              link.download = 'qrcode.png';
              link.href = canvas.toDataURL('image/png');
              link.click();
            })
            .catch((error) => {
              console.error('Error creating QR code image:', error);
            });
        }
      };
      
    return (
        <>
        {
            error404 ?
            <Page404 />
            :
            <>
            <ul className={`fixed-bottom-nav ${menuShow ? "show" : ""}`}>
                <li className='open-close'>
                    <button onClick={() => menuToogler()}><i className='bx bx-left-arrow-alt'></i></button>
                </li>
                {
                    controls &&
                    <>
                    <li>
                        <Link to="/user-profile-card-management" className='btn-publish'>
                        <i className='bx bxs-id-card' ></i> Card Management
                        </Link>
                    </li>
                    <li>
                        <Link to={`/update-card/${id}`} className='btn-publish'>
                            <i className='bx bx-edit'></i> Edit
                        </Link>
                    </li>
                    <li>
                        <button className='btn-publish' onClick={() => handleUnPublish()}>
                            <i className='bx bx-log-out-circle'></i> Unpublish
                        </button>
                    </li>
                    </>
                }
                <li>
                    <div className="share-whatsapp">
                        <input type="text" value={wpNumber} onChange={handleChange }/>
                        <button onClick={() => handlesendWhatsapp()}><i className='bx bxl-whatsapp'></i> Whatsapp</button>
                    </div>
                </li>
                <li>
                    <button className="btn-publish" onClick={() => handlecopyUrl()}>
                        <i className='bx bx-copy'></i> Copy Link
                    </button>
                </li>
                <li>
                    <Link className='btn-publish' to={`${backendUrl}/vcard/download/${id}`} download={true}>
                        <i className='bx bxs-contact'></i> Download VFC
                    </Link>
                </li>
                <li>
                    <button className='btn-publish' type="button" data-bs-toggle="modal" data-bs-target="#shareModal">
                        <i className='bx bx-share'></i> Share
                    </button>
                </li>
            </ul>
            <div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Share</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-0">
                            <div ref={qrRef} style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                                <QRCode value={window.location.href} size={280}/>
                            </div>
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "33.33%" }} data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-danger d-md-block d-sm-none d-none" style={{ width: "33.333%" }} onClick={() => handleCopy()}><i className='bx bx-qr'></i> Copy Qr</button>
                            <button type="button" class="btn btn-danger d-md-none d-sm-block" style={{ width: "33.333%" }} onClick={() => handleShare()}><i className='bx bx-share'></i> Send</button>
                            <button type="button" className="btn btn-warning" style={{ width: "33.333%" }} onClick={() => handleDownload()}><i className='bx bx-download'></i> Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <DigitalCardLayout>
                {filteredCardData && (
                    <div dangerouslySetInnerHTML={{ __html: filteredCardData.html }}></div>
                )}
            </DigitalCardLayout>
            </>
        }
        </>
    )
}

export default DynamicCard;