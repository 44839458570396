import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout"
import ProfileCardHeader from "../components/ProfileCardHeader";
import UserProfileCommonPanel from "../components/UserProfileCommonPanel";
import EditDigitalCard from "./EditDigitalCard";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../data/Url";
const UserProfileCardManagement = () => {
    const navigate = useNavigate();
    const [cardData, setCardData] = useState([]);
    const user = JSON.parse(Cookies.get("user"));
    const [cardId, setCardId] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/fetch-all-card/${user.userid}`);
                setCardData(res.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [])
    const handlecopyUrl = (url) => {
        const text = url;
        navigator.clipboard.writeText(text).catch(err => {
            console.error('Failed to copy text: ', err);
          });
    }
    const handleDeleteCard = async (paramid) => {
        try {
            const res = await axios.get(`${backendUrl}/delete-card/${paramid}`);
            const status = res.data.Status;
            if (status === 200) {
                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handlePublish = async (cardid) => {
        try {
            const res = await axios.post(`${backendUrl}/publish-card`, {
                cid: cardid,
                isPublished: true
            });
            const status = res.data.Status;
            if (status === 200) {
                toast.success("Your card is successfully published", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleUnPublish = async (paramid) => {
        try {
            const res = await axios.post(`${backendUrl}/unpublish-card/${paramid}`);
            const status = res.data.Status;
            if (status === 200) {
                toast.success("Your card is successfully unpublished", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    let c = 0;
    return (
        <Layout>
            <div className="container">
                <div className="row userprofile_layout">
                    <UserProfileCommonPanel user={user}/>
                    <div className="col-md-9">
                        <div className="card border-0">
                            <ProfileCardHeader title="Card Management"/>
                            <div className="card-body">
                                {cardData.length == 0 ? <p>No Records Found!</p> : 
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-table">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Card Name</th>
                                                <th>Card Link</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cardData.map((ele, index) => {
                                                c++;
                                                return (
                                                    <tr key={ele.ID}>
                                                        <td>{c}</td>
                                                        <td>{ele.CardName}</td>
                                                        {/* <td><Link to={`/preview/${ele._id}`} target="_blank">http://localhost:3000/preview/{ele._id}</Link> <i className='bx bx-copy' onClick={() => handlecopyUrl(`http://localhost:3000/preview/${ele._id}`)}></i></td> */}
                                                        <td className="card-links">
                                                            {
                                                                ele.isPublished ?
                                                                <><Link to={`/card/${ele.ID}`}>https://b2badvertising.co.in/card/{ele.ID}</Link> <i className='bx bx-copy' onClick={() => handlecopyUrl(`https://b2badvertising.co.in/card/${ele.ID}`)}></i></>
                                                                :
                                                                <><Link to={`/preview-card/${ele.ID}`}>https://b2badvertising.co.in/preview-card/{ele.ID}</Link></>
                                                            }
                                                        </td>
                                                        {/* <td>{ele.paid ? "Confirmed" : "Pending"}</td> */}
                                                        <td>
                                                            <div className="d-flex gap-1">
                                                            {
                                                                ele.isPublished ?
                                                                <><Link to={`/card/${ele.ID}`} className="btn btn-primary"><i class="fa-solid fa-eye"></i></Link></>
                                                                :
                                                                <><Link to={`/preview-card/${ele.ID}`} className="btn btn-primary"><i class="fa-solid fa-eye"></i></Link></>
                                                            }
                                                                <Link className="btn btn-secondary" to={`/update-card/${ele.ID}`}><i className='bx bx-edit'></i></Link>
                                                                {
                                                                    ele.isPublished ?
                                                                    <button className="btn btn-primary bg-black unpulish-btn" onClick={() => handleUnPublish(ele.ID)}><i className='bx bx-upload'></i></button>
                                                                    :
                                                                    <button className="btn btn-primary bg-black" onClick={() => handlePublish(ele.ID)}><i className='bx bx-upload'></i></button>
                                                                }
                                                                <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={() => setCardId(ele.ID)}><i className='bx bx-trash'></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Card</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to delete the card ?
                        </div>
                        <div class="modal-footer" style={{flexWrap: "nowrap"}}>
                            <button type="button" style={{width: "50%"}} class="btn btn-default" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" style={{width: "50%"}} class="btn btn-danger" onClick={() => handleDeleteCard(cardId)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserProfileCardManagement;