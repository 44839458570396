import { useEffect } from "react";
const ForgotPassword = () => {
    useEffect(() => {
        import("./Login.css");
    }, [])
    return (
        <div className='form-wrapper-layout'>
            <div className="row m-0">
                <div className="col-md-8 p-0">
                    <div className="login-leftbox">
                        <img src="assets/images/fp.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="login-rightbox">
                        <div style={{width: "100%"}}>
                            <div>
                                <h1 className='mb-4'>Forget Password</h1>
                                <div className="mb-2">
                                    <input id="email" placeholder="Email" type="email"/>
                                    {/* {showEmailError && <p className='text-danger mb-0'>Email Required!</p>} */}
                                </div>
                                <button className="btn btn-primary btn-main w-100 mb-3" type="button" data-bs-toggle="modal" data-bs-target="#codeModal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="codeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-black" id="exampleModalLabel">Verify</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p className="text-black">An OTP has been sent to your email id</p>
                            <div class="verification-code--inputs">
                                <input type="text" maxlength="1" />
                                <input type="text" maxlength="1" />
                                <input type="text" maxlength="1" />
                                <input type="text" maxlength="1" />
                                <input type="text" maxlength="1" />
                                <input type="text" maxlength="1" />
                            </div>
                            <input type="hidden" id="verificationCode" />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;