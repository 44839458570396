import AdminBreadCrumb from "../components/AdminBreadCrumb";
import AdminLayout from "../components/AdminLayout";
import { useState, useEffect } from "react";
import $ from 'jquery';
import axios from "axios";
import { backendUrl } from "../data/Url";
const Enquiries = () => {
    
    const [enquiryData, setEnquiryData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-enquiry`);
                setEnquiryData(res.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();

        $("#selectAll").on("change", function () {
            const isChecked = $(this).prop("checked");
            $(".admin-table tbody input[type=checkbox]").prop("checked", isChecked);
            setBtndisable(!isChecked);
        });
        $(".admin-table tbody input[type=checkbox]").on("change", function () {
            if (!$(this).prop("checked")) {
                $("#selectAll").prop("checked", false);
                if (!$(".admin-table tbody input[type=checkbox]").is(":checked")) {
                    setBtndisable(true);
                }
            } else {
                setBtndisable(false);
            }
        });
        var pageh = $(window).height();
        var ex = 65 + 49 + 100 + 38 + 16;
        var total = pageh - ex;
        $(".scroll").height(total + "px");


    }, [])

    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [btndisable, setBtndisable] = useState(true);
    const totalPages = Math.ceil(enquiryData.length / itemsPerPage);
    const handleFirst = () => setCurrentPage(1);
    const handlePrev = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNext = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handleLast = () => setCurrentPage(totalPages);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const currentPageData = enquiryData.slice(startIdx, startIdx + itemsPerPage);
    
    const [fetchMessage, setFetchMessage] = useState("");
    const handleFetchMessage = (id) => {
        const enquiry = enquiryData.find(item => item.ID === id);
        if (enquiry) {
            setFetchMessage(enquiry.Message);
        } else {
            setFetchMessage("Message not found");
        }
    }
    const [deleteId, setDeleteId] = useState("");
    const handleDeleteEnquiry = async (id) => {
        try {
            const res = await axios.get(`${backendUrl}/admin/delete-enquiry/${id}`);
            const status = res.data.Status;
            if (status === 200) {
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <AdminLayout>
            <AdminBreadCrumb title="Enquiries"/>
            <div className="main-layout">
                <div className="container-fluid">
                    {
                        enquiryData.length !== 0 ? 
                        <>
                        <div className="table-responsive scroll">
                            <table className="table table-hover table-striped admin-table registration-table">
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <input type="checkbox" id="selectAll"/>
                                                <div class="dropdown">
                                                    <button class="btn p-0" style={{ position: "relative", top: "2px", color: "#fff", border: "none" }} type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={btndisable ? true : false}>
                                                        <i className='bx bx-chevron-down' ></i>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <li><button class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#bulkdeleteModal">Bulk Delete</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </th>
                                        <th>Actions</th>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>IP</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentPageData.map((el, index) => {
                                            return (
                                                <tr key={el.ID}>
                                                    <td>{startIdx + index + 1}</td>
                                                    <td><input type="checkbox" id={`user${el.ID}`}/></td>
                                                    <td>
                                                        <div className="d-flex gap-1">
                                                            <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#enquirydetailsModal" onClick={() => handleFetchMessage(el.ID)}><i className='bx bx-chat'></i></button>
                                                            <button className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#deleteRegistrationModal" onClick={() => setDeleteId(el.ID)}><i className='bx bxs-trash'></i></button>
                                                        </div>
                                                    </td>
                                                    <td>{el.ID}</td>
                                                    <td>{el.FirstName} {el.LastName}</td>
                                                    <td>{el.Email}</td>
                                                    <td>{el.PhoneCode} {el.Phone}</td>
                                                    <td>{el.IPAddress}</td>
                                                    <td>{el.AddedDate}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            enquiryData.length !== 0 ? 
                            <nav aria-label="...">
                            <ul class="pagination mb-0 mt-3 justify-content-end">
                                {
                                    currentPage > 1 && (
                                        <>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleFirst}>First</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handlePrev}>Previous</button>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    Array.from({ length: totalPages }, (_, index) => (
                                        <li class={`page-item ${currentPage === index + 1 ? "active": ""}`} key={index}>
                                            <button class="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                        </li>
                                    ))
                                }
                                {
                                    currentPage < totalPages && (
                                        <>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleNext}>Next</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link" onClick={handleLast}>Last</button>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </nav> : ""
                        }
                        </>
                    :
                    <p className="mb-0">No Results Found!</p>
                    }
                </div>
            </div>
            <div class="modal fade text-black" id="deleteRegistrationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Delete User</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to delete the user ?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" style={{ width: "50%" }} onClick={() => handleDeleteEnquiry(deleteId)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="enquirydetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Enquiry Message</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {fetchMessage}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="bulkdeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Bulk Delete Users</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to bulk delete the users ?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" style={{ width: "50%" }}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Enquiries;