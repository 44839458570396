import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Profile from '../assets/user.png';
import { backendUrl, imageUrl } from '../data/Url';
const UserProfileCommonPanel = ({ user }) => {
    const [imageSrc, setImageSrc] = useState(Profile);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const userID = JSON.parse(Cookies.get("user")).userid;
                const res = await axios.get(`${backendUrl}/fetch-user-image/${userID}`);
                console.log(res.data);
                const status = res.data.Status;
                if (status === 200) {
                    let currentDate = new Date();
                    let dt = currentDate.getDate() + currentDate.getTime();
                    if (res.data.ProfileImage !== null) {
                        setImageSrc(`${imageUrl}/${res.data.ProfileImage}?v=${dt}`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchImage();
    }, [])

    const handleImageChange = async (e) => {
        try {
            const userID = JSON.parse(Cookies.get("user")).userid;
            const file = e.target.files[0];
    
            if (file) {
                const imageUrl = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
    
                setImageSrc(imageUrl);
    
                const formData = new FormData();
                formData.append("url", imageUrl);
                formData.append("id", userID);
    
                await axios.post(`${backendUrl}/upload-user-profile`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="col-md-3">
            <div className="user-profile-left">
                <div className="profilepnel-header">
                    <div className="profile-pic">
                        <img src={imageSrc} alt="profile"/>
                        <input type="file" accept="image/*" className="image-selector" capture onChange={handleImageChange}/>
                        <div className="camera-box">
                            <i className='bx bxs-camera'></i>
                            <input type="file" accept="image/*" capture onChange={handleImageChange}/>
                        </div>
                    </div>
                    <h1 className="h3 mt-2 mb-0 text-black">{user.fname} {user.lname}</h1>
                    <p className="mb-0 text-black">{user.email}</p>
                </div>
                <ul className="profilepanel-btns">
                    <li><Link to="/user-profile"><i className='bx bx-user'></i> User Profile</Link></li>
                    <li><Link to="/user-profile-update"><i className='bx bx-upload'></i> Update Profile</Link></li>
                    {/* <li><Link to="/user-profile-orders"><i className='bx bx-cart'></i> My Orders</Link></li> */}
                    <li><Link to="/user-profile-card-management"><i className='bx bxs-id-card'></i> Card Management</Link></li>
                    {/* <li><Link to="/user-profile-feedback"><i className='bx bx-message-dots'></i> Feedbacks</Link></li>
                    <li><Link to="/user-profile-contact"><i className='bx bxs-contact'></i> Contacts</Link></li> */}
                </ul>
            </div>
        </div>
    )
}

export default UserProfileCommonPanel;