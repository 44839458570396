import Layout from "../components/Layout";
import { useEffect, useRef, useState } from 'react';
import phoneCodes from '../data/phonecode.json';
import $ from 'jquery';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../data/Url";
const ContactUs = () => {
    const selectRef = useRef(null);
    const [options, setOptions] = useState([]);
    const [phoneCode, setPhoneCode] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    useEffect(() => {
        import("./Contact.css");
    }, [])
    useEffect(() => {
        setOptions(phoneCodes);
        $(selectRef.current).select2({
            width: "100%",
            dropdownParent: $(".phone-country-select")
        });

        const phone_Code = $("#phone_code").val();
        setPhoneCode(phone_Code);

        return () => {
            $(selectRef.current).select2('destroy')
        }
    })
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [messageValid, setMessageValid] = useState(false);
    const [userId, setUserId] = useState("0");
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            var valid = true;
            if (firstName.length == 0) {
                setFirstNameValid(true);
                valid = false;
            } else {
                setFirstNameValid(false);
            }
            if (lastName.length == 0) {
                setLastNameValid(true);
                valid = false;
            } else {
                setLastNameValid(false);
            }
            if (email.length == 0) {
                setEmailValid(true);
                valid = false;
            } else {
                setEmailValid(false);
            }
            if (phone.length == 0) {
                setPhoneValid(true);
                valid = false;
            } else {
                setPhoneValid(false);
            }
            if (message.length == 0) {
                setMessageValid(true);
                valid = false;
            } else {
                setMessageValid(false);
            }
            if (valid) {
                if (Cookies.get("user")) {
                    setUserId(JSON.parse(Cookies.get("user")).userid);
                }
                const newContact = {
                    FirstName: firstName,
                    LastName: lastName,
                    Email: email,
                    Phone: phone,
                    PhoneCode: phoneCode,
                    Message: message,
                    UserId: userId
                }
                const res = await axios.post(`${backendUrl}/save-enquiry`, newContact);
                console.log(res);
                const status = res.data.Status;
                const text = res.data.Message;
                if (status === 200) {
                    toast.success(text, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        onClose: () => window.location.reload()
                    })
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setMessage("");
                } else if (status === 500) {
                    toast.error(text, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Layout>
            <div className="inner-page-wrapper">
                <div className="container">
                    <div className="subheading">Get in Touch with US</div>
                    <h1 className="main-heading">Contact Us for Digital Business <span>Card</span></h1>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="custom-form-fields">
                                        <input type="text" className="custom-input-field" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                        <label className="custom-field-label">First Name</label>
                                        {firstNameValid && <p className="text-danger mb-0">First Name Required!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="custom-form-fields">
                                        <input type="text" className="custom-input-field" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                        <label className="custom-field-label">Last Name</label>
                                        {lastNameValid && <p className="text-danger mb-0">Last Name Required!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="custom-form-fields">
                                        <input type="email" className="custom-input-field" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <label className="custom-field-label">Email</label>
                                        {emailValid && <p className="text-danger mb-0">Email Required!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="row m-0">
                                        <div className="col-4 p-0">
                                            <div className='phone-country-select'>
                                                <select ref={selectRef} id="phone_code">
                                                    {
                                                        options.map((el, index) => {
                                                            return (
                                                                <option key={index} value={el.dial_code} selected={el.dial_code === "+91"}>
                                                                    {el.dial_code} ({el.name})
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-8 p-0">
                                            <div className="custom-form-fields">
                                                <input type="tel" className="custom-input-field" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                                <label className="custom-field-label">Phone</label>
                                            </div>
                                        </div>
                                    </div>
                                    {phoneValid && <p className="text-danger mb-0">Phone Required!</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-4">
                                    <div className="custom-form-fields">
                                        <textarea className="custom-textarea-field" onChange={(e) => setMessage(e.target.value)}></textarea>
                                        <label className="custom-field-label">Message</label>
                                        {messageValid && <p className="text-danger mb-0">Message Required!</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button className="primary-btn" type="submit">Send <i className='bx bxs-send'></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs;