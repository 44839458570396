import AdminBreadCrumb from "../components/AdminBreadCrumb";
import AdminLayout from "../components/AdminLayout"
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import Editor from "../components/Editor";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../data/Url";
const Themes = () => {
    const [themedata, setThemedata] = useState([]);
    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [btndisable, setBtndisable] = useState(true);
    const totalPages = Math.ceil(themedata.length / itemsPerPage);
    const handleFirst = () => setCurrentPage(1);
    const handlePrev = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNext = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handleLast = () => setCurrentPage(totalPages);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const currentPageData = themedata.slice(startIdx, startIdx + itemsPerPage);
    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(`${backendUrl}/admin/fetch-all-theme`);
                setThemedata(res.data);
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();

        $("#selectAll").on("change", function () {
            const isChecked = $(this).prop("checked");
            $(".admin-table tbody input[type=checkbox]").prop("checked", isChecked);
            setBtndisable(!isChecked);
        });
        $(".admin-table tbody input[type=checkbox]").on("change", function () {
            if (!$(this).prop("checked")) {
                $("#selectAll").prop("checked", false);
                if (!$(".admin-table tbody input[type=checkbox]").is(":checked")) {
                    setBtndisable(true);
                }
            } else {
                setBtndisable(false);
            }
        });
        var pageh = $(window).height();
        var ex = 65 + 49 + 100 + 38 + 16 + 55 + 16 + 16 + 5;
        var total = pageh - ex;
        $(".scroll").height(total + "px");
    }, []);

    const [htmlEditor, setHtmlEditor] = useState("");
    const [htmlEditorUp, setHtmlEditorUp] = useState("");
    const [themeName, setThemeName] = useState("");
    const [themeImage, setThemeImage] = useState(null);
    const [themeCSS, setThemeCSS] = useState(null);
    const [themeNameUp, setThemeNameUp] = useState("");
    const [themeImageUp, setThemeImageUp] = useState(null);
    const [themeCSSUp, setThemeCSSUp] = useState(null);
    const [themeRoute, setThemeRoute] = useState("");
    const [themeRouteUp, setThemeRouteUp] = useState("");
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setThemeImage(file);
    }
    const handleFileChange2 = (e) => {
        const file = e.target.files[0];
        setThemeCSS(file);
    }
    const handleFileChangeUp = (e) => {
        const file = e.target.files[0];
        setThemeImageUp(file);
    }
    const handleFileChange2Up = (e) => {
        const file = e.target.files[0];
        setThemeCSSUp(file);
    }
    const handleSaveTheme = async () => {
        try {
            const formData = new FormData();
            formData.append("ThemeName", themeName);
            formData.append("ThemeImage", themeImage);
            formData.append("ThemeCss", themeCSS);
            formData.append("html", htmlEditor);
            formData.append("ThemeRoute", themeRoute);
            console.log(...formData.entries());
            const res = await axios.post(`${backendUrl}/admin/save-theme-info`, formData, {
                'Content-Type': 'multipart/form-data'
            });
            const status = res.data.Status;
            const message = res.data.Message;
            if (status === 200) {
                toast.success(message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            } else if (status === 500) {
                toast.warning(message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            } else {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    const handleDownload = (downloadLink) => {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = true;
        document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
    }
    
    const [fetchHtml, setFetchHtml] = useState("");
    const handleFetchHtml = (id) => {
        const res = themedata.find(item => item.ID === id);
        setFetchHtml(res.html);
    }
    const [themeupID, setThemeupID] = useState("");
    const [themeDesignImage, setThemeDesignImage] = useState("");
    const [themeDesignCss, setThemeDesignCss] = useState("");
    const handleGetTheme = async (id) => {
        try {
            const res = await axios.get(`${backendUrl}/admin/fetch-theme/${id}`);
            setThemeNameUp(res.data.ThemeName);
            setThemeDesignImage(`${backendUrl}${res.data.ThemeImage}`);
            setThemeDesignCss(`${res.data.ThemeCss.split('/').pop()}`);
            setHtmlEditorUp(res.data.html);
            setThemeupID(id);
            setThemeRouteUp(res.data.ThemeRoute);
        } catch (error) {
            console.log(error);
        }
    }
    const handleUpdateTheme = async (id) => {
        try {
            // const updateThemeObj = {
            //     ThemeName: themeNameUp,
            //     // ThemeImage: themeImageUp,
            //     // ThemeCss: themeCSSUp,
            //     html: htmlEditorUp,
            //     ID: id,
            //     ThemeRoute: themeRouteUp
            // }
            const formData = new FormData();
            formData.append("ThemeName", themeNameUp);
            formData.append("html", htmlEditorUp);
            formData.append("ThemeRoute", themeRouteUp);
            formData.append("ID", id);
            const res = await axios.post(`${backendUrl}/admin/update-theme`, formData, {
                'Content-Type': 'multipart/form-data'
            });
            const status = res.data.Status;
            if (status === 200) {
                toast.success(res.data.Message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [deleteID, setDeleteID] = useState("");
    const handleDelete = async (id) => {
        try {
            await axios.get(`${backendUrl}/admin/delete-theme/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <AdminLayout>
            <AdminBreadCrumb title="Themes"/>
            <div className="main-layout">
                <div className="container-fluid">
                    <div className="card card-black border-0">
                        <div className="card-header">
                            <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#addDesignModal">Add Design</button>
                        </div>
                        <div className="card-body">
                            {
                                themedata.length !== 0 ?
                                <>
                                <div className="table-responsive scroll">
                                    <table className="table table-hover table-striped admin-table registration-table">
                                        <thead>
                                            <tr>
                                                <th>Sl</th>
                                                <th>
                                                    <div className="d-flex align-items-center">
                                                        <input type="checkbox" id="selectAll"/>
                                                        <div class="dropdown">
                                                            <button class="btn p-0" style={{ position: "relative", top: "2px", color: "#fff", border: "none" }} type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={btndisable ? true : false}>
                                                                <i className='bx bx-chevron-down' ></i>
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li><button class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#bulkdeleteModal">Bulk Delete</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th>Actions</th>
                                                <th>ID</th>
                                                <th>Theme Name</th>
                                                <th>Image</th>
                                                <th>Css</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentPageData.map((el, index) => {
                                                    return (
                                                        <tr key={el.ID}>
                                                            <td>{startIdx + index + 1}</td>
                                                            <td><input type="checkbox" id={`user${el.ID}`}/></td>
                                                            <td>
                                                                <div className="d-flex gap-1">
                                                                    <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#viewHtmlModal" onClick={() => handleFetchHtml(el.ID)}><i className='bx bxs-file-html'></i></button>
                                                                    <button className="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#editThemeModal" onClick={() => handleGetTheme(el.ID)}><i className='bx bxs-pencil'></i></button>
                                                                    <button className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#deleteThemeModal" onClick={() => setDeleteID(el.ID)}><i className='bx bxs-trash'></i></button>
                                                                </div>
                                                            </td>
                                                            <td>{el.ID}</td>
                                                            <td>{el.ThemeName}</td>
                                                            <td>
                                                                {/* <button className="btn btn-primary" onClick={() => handleDownload(`http://localhost:55459/${el.ThemeImage}`)}>Download</button> */}
                                                                <img src={`${backendUrl}/${el.ThemeImage}`} alt="theme" style={{ maxWidth: "60px" }}/>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-primary" onClick={() => handleDownload(`http://localhost:55459/${el.ThemeCss}`)}>Download</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    themedata.length !== 0 ? 
                                        <nav aria-label="...">
                                            <ul class="pagination mb-0 mt-3 justify-content-end">
                                                {
                                                    currentPage > 1 && (
                                                        <>
                                                            <li class="page-item">
                                                                <button class="page-link" onClick={handleFirst}>First</button>
                                                            </li>
                                                            <li class="page-item">
                                                                <button class="page-link" onClick={handlePrev}>Previous</button>
                                                            </li>
                                                        </>
                                                    )
                                                }
                                                {
                                                    Array.from({ length: totalPages }, (_, index) => (
                                                        <li class={`page-item ${currentPage === index + 1 ? "active": ""}`} key={index}>
                                                            <button class="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                                        </li>
                                                    ))
                                                }
                                                {
                                                    currentPage < totalPages && (
                                                        <>
                                                            <li class="page-item">
                                                                <button class="page-link" onClick={handleNext}>Next</button>
                                                            </li>
                                                            <li class="page-item">
                                                                <button class="page-link" onClick={handleLast}>Last</button>
                                                            </li>
                                                        </>
                                                    )
                                                }
                                            </ul>
                                        </nav> : ""
                                }
                                </>
                                :
                                <p className="mb-0">No Results Found!</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="addDesignModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Add Design</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="mb-2">
                                <input type="text" placeholder="Design Name" className="form-control" onChange={(e) => setThemeName(e.target.value)}/>
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Design Image</label>
                                <input type="file" className="form-control" accept="image/*" onChange={handleFileChange}/>
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Design CSS</label>
                                <input type="file" className="form-control" onChange={handleFileChange2}/>
                            </div>
                            <div className="mb-2">
                                {/* <Editor value={htmlEditor} onChange={handleHtml} height="400px"/> */}
                                <textarea onChange={(e) => setHtmlEditor(e.target.value)} className="form-control" style={{ height: "400px" }}></textarea>
                            </div>
                            <div>
                                <label className="form-label">Theme Route</label>
                                <input type="text" className="form-control" onChange={(e) => setThemeRoute(e.target.value)}/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={() => handleSaveTheme()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="editThemeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Design</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="mb-2">
                                <input type="text" placeholder="Design Name" className="form-control" value={themeNameUp} onChange={(e) => setThemeNameUp(e.target.value)}/>
                            </div>
                            <div className="mb-2">
                                <div>
                                    <img src={themeDesignImage} alt="themedesign" style={{ maxWidth: "100px" }}/>
                                </div>
                                <label className="form-label">Design Image</label>
                                <input type="file" className="form-control" accept="image/*" onChange={handleFileChangeUp}/>
                            </div>
                            <div className="mb-2">
                                <p>{themeDesignCss}</p>
                                <label className="form-label">Design CSS</label>
                                <input type="file" className="form-control" onChange={handleFileChange2Up}/>
                            </div>
                            <div>
                                <textarea onChange={(e) => setHtmlEditorUp(e.target.value)} className="form-control" style={{ height: "400px" }} value={htmlEditorUp}></textarea>
                            </div>
                            <div>
                                <label className="form-label">Theme Route</label>
                                <input type="text" className="form-control" value={themeRouteUp} onChange={(e) => setThemeRouteUp(e.target.value)}/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={() => handleUpdateTheme(themeupID)}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="viewHtmlModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">HTML</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div dangerouslySetInnerHTML={{ __html: fetchHtml }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="deleteThemeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Theme</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to delete the theme ?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" class="btn btn-default" style={{ width: "50%" }} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" style={{ width: "50%" }} onClick={() => handleDelete(deleteID)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Themes;