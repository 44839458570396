import Layout from "../components/Layout"
import ProfileCardHeader from "../components/ProfileCardHeader";
import UserProfileCommonPanel from "../components/UserProfileCommonPanel";
import Cookies from "js-cookie";
const UserProfileContact = () => {
    const user = JSON.parse(Cookies.get("user"));
    return (
        <Layout>
            <div className="container">
                <div className="row userprofile_layout">
                    <UserProfileCommonPanel user={user}/>
                    <div className="col-md-9">
                        <div className="card border-0">
                            <ProfileCardHeader title="Contacts"/>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-table">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Card Name</th>
                                                <th>Response From</th>
                                                <th>Response Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Corporate Blue</td>
                                                <td>John Doe</td>
                                                <td>27/05/2024</td>
                                                <td>
                                                    <div className="d-flex gap-1">
                                                        <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#contactDetailsModal"><i className="fa-solid fa-eye"></i></button>
                                                        <button className="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#deletecontactModal"><i className='bx bxs-trash' ></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="contactDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-black" id="exampleModalLabel">Contact Details</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="table-reponsive">
                                <table className="table table-striped table-hover card-table">
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>:</td>
                                            <td>John Doe</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>:</td>
                                            <td>johndoe@example.com</td>
                                        </tr>
                                        <tr>
                                            <th>Contact</th>
                                            <td>:</td>
                                            <td>6290342523</td>
                                        </tr>
                                        <tr>
                                            <th>Message</th>
                                            <td>:</td>
                                            <td>Good</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-black" id="deletecontactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Contact</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Are you sure to delete the contact?
                        </div>
                        <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                            <button type="button" style={{ width: "50%" }} class="btn btn-default" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" style={{ width: "50%" }} class="btn btn-danger">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserProfileContact;