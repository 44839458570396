import { useEffect } from "react";
import Layout from "../components/Layout"

const Page404 = () => {
    useEffect(() => {
        import("./Error.css");
    }, [])
    return (
        <Layout>
            <div className="container">
                <div className="error-page-wrapper">
                    <h1 className="mb-0 text-center">404</h1>
                    <h2 className="mb-0 text-center">Page Not Found</h2>
                </div>
            </div>
        </Layout>
    )
}

export default Page404;