import { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout"
import ProfileCardHeader from "../components/ProfileCardHeader";
import UserProfileCommonPanel from "../components/UserProfileCommonPanel";
import $ from 'jquery';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import phoneCodes from '../data/phonecode.json';
import Countries from '../data/Country.json';
import Cities from '../data/city.json';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { backendUrl } from "../data/Url";
import { toast } from "react-toastify";
const UserProfileUpdate = () => {
    const navigate = useNavigate();
    const selectRef = useRef(null);
    const selectRef2 = useRef(null);
    const selectRef3 = useRef(null);
    const selectRef4 = useRef(null);
    const user = JSON.parse(Cookies.get("user"));
    const [fname, setFname] = useState(user.fname);
    const [lname, setLname] = useState(user.lname);
    const [email, setEmail] = useState(user.email);
    const [phoneCode, setPhoneCode] = useState(user.phone_code);
    const [phone, setPhone] = useState(user.phone);
    const [options, setOptions] = useState([]);
    const [company, setCompany] = useState(user.company);
    const [address, setAddress] = useState(user.address);
    const [country, setcountry] = useState(user.country);
    const [countries, setCountries] = useState([]);
    const [state, setState] = useState(user.state);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState(user.city);
    const [cities, setCities] = useState([]);
    const [pincode, setPincode] = useState(user.pin);

    useEffect(() => {
        setCountries(Countries);
        setCities(Cities);
        import('./Bootstrap-form.css');
        import('./Login.css');
        import('./Form-new.css');
        setOptions(phoneCodes);

        $(selectRef.current).select2({ width: '100%', dropdownParent: $(".phone-country-select") });
        $(selectRef2.current).select2({ width: '100%', dropdownParent: $(".country_drop") });
        $(selectRef3.current).select2({ width: '100%', dropdownParent: $(".state_drop") });
        $(selectRef4.current).select2({ width: '100%', dropdownParent: $(".city_drop") });

        $("#select_country").on("change", function (e) {
            const selectedCountry = e.target.value;
            setcountry(selectedCountry);
            const statesData = countries.find(c => c.name.toLowerCase() === country.toLowerCase());
            if (statesData) {
                setStates(statesData.states);
            } else {
                setStates([]);
            }
        })
        $(".state_drop select").on("change", function (e) {
            const selectedState = e.target.value;
            setState(selectedState);
            const citiesData = Cities.find(c => c.name.toLowerCase() === state.toLowerCase());
            if (citiesData) {
                setCities(citiesData.cities);
            } else {
                setCities([]);
            }
        })

        $("#select_city").on("change", function (e) {
            const selectedCity = e.target.value;
            setCity(selectedCity);
        })

        return () => {
            $(selectRef.current).select2('destroy');
            $(selectRef2.current).select2('destroy');
            $(selectRef3.current).select2('destroy');
            $(selectRef4.current).select2('destroy');
        };
    }, [Countries, Cities]);

    useEffect(() => {
        const statesData = countries.find(c => c.name.toLowerCase() === country.toLowerCase());
        if (statesData) {
            setStates(statesData.states);
        } else {
            setStates([]);
        }
    }, [country, countries]);

    useEffect(() => {
        const citiesData = Cities.find(c => c.name.toLowerCase() === state.toLowerCase());
        if (citiesData) {
            setCities(citiesData.cities);
        } else {
            setCities([]);
        }
    }, [state, Cities]);

    useEffect(() => {
        if (selectRef3.current) {
            $(selectRef3.current).select2('destroy').select2({ width: '100%', dropdownParent: $(".state_drop") });
        }
    }, [states]);

    useEffect(() => {
        if (selectRef4.current) {
            $(selectRef4.current).select2('destroy').select2({ width: '100%', dropdownParent: $(".city_drop") });
        }
    }, [cities]);
    const handleUpdateUser = async (e) => {
        try {
            const updateUser = {
                FirstName: fname,
                LastName: lname,
                PhoneCode: phoneCode,
                Phone: phone,
                Email: email,
                Company: company,
                Address: address,
                Country: country,
                State: state,
                City: city,
                PinCode: pincode
            }
            const res = await axios.put(`${backendUrl}/update-user/${user.userid}`, updateUser);
            const dt = res.data;
            const status = dt.Status;
            if (status === 200) {
                Cookies.remove("user");
                const upUser = {
                    "userid": dt.UserID,
                    "fname": dt.FirstName,
                    "lname": dt.LastName,
                    "phone_code": dt.PhoneCode,
                    "phone": dt.Phone,
                    "email": dt.Email,
                    "company": dt.Company,
                    "address": dt.Address,
                    "country": dt.Country,
                    "state": dt.State,
                    "city": dt.City,
                    "pin": dt.PinCode
                };
                Cookies.set("user", JSON.stringify(upUser));
                toast.success("User updated successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.reload()
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Layout>
            <div className="container">
                <div className="row userprofile_layout">
                    <UserProfileCommonPanel user={user}/>
                    <div className="col-md-9">
                        <div className="card border-0">
                            <ProfileCardHeader title="User Profile Update"/>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <input type="text" placeholder="First Name" className="form-control" value={fname} onChange={(e) => setFname(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <input type="text" placeholder="Last Name" className="form-control" value={lname} onChange={(e) => setLname(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <input type="email" placeholder="Email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="row m-0">
                                            <div className="col-4 phone-country-select p-0">
                                                <select ref={selectRef} onChange={(e) => setPhoneCode(e.target.value)}>
                                                    {
                                                        options.map((el, index) => {
                                                            return (
                                                                <option key={index} value={el.dial_code} selected={el.dial_code === phoneCode}>
                                                                    {el.dial_code} ({el.name})
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-8 p-0">
                                                <input type="tel" id="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <input type="text" placeholder="Company Name" className="form-control" value={company} onChange={(e) => setCompany(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 mb-2"></div>
                                    <div className="col-md-6 mb-2">
                                        <input type="text" placeholder="Address" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="country_drop">
                                            <select ref={selectRef2} id="select_country">
                                                <option>-- Country --</option>
                                                {
                                                    countries.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name} selected={el.name === country}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="state_drop">
                                            <select ref={selectRef3} id="select_state">
                                                <option>-- State --</option>
                                                {
                                                    states.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name} selected={el.name === state}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="city_drop">
                                            <select ref={selectRef4} id="select_city">
                                                <option>-- City --</option>
                                                {
                                                    cities.map((el, index) => {
                                                        return (
                                                            <option key={index} value={el.name} selected={el.name === city}>
                                                                {el.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button className="btn primary-btn" onClick={handleUpdateUser}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserProfileUpdate;