import { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';
const AdminLayout = ({ children }) => {
    useEffect(() => {
        import("./Admin.css");
    })
    const [sidebarToogle, setSidebarToogle] = useState(false);
    const handleToogle = () => {
        setSidebarToogle(!sidebarToogle);
    }
    return (
        <>
        <AdminSidebar toogler={sidebarToogle}/>
        <div className={`wrapper ${sidebarToogle ? "toogle" : ""}`}>
            <AdminHeader func={handleToogle}/>
            {children}
        </div>
        </>
    )
}

export default AdminLayout;