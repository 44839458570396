import { useEffect, useState } from "react";
import Layout from "../components/Layout"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import SideCart from "../components/SideCart";
import { Link } from "react-router-dom";
const Pricing = () => {
    const data = [];
    useEffect(() => {
        import('./pricing.css');
    }, [])
    const [show, setShow] = useState(false);
    const handleSidecartToogle = (val) => {
        setShow(val);
    }
    return (
        <Layout>
            <div className="inner-page-wrapper">
                <div className="container">
                    <div className="subheading">View Pricing to get</div>
                    <h1 className="main-heading">You Own Digital Business <span>Card</span></h1>
                    <div className="pricing-table desktop-table">
                        <div className="pricing-table-row pricing-table-heading">
                            <div className="pt-col"></div>
                            <div className="pt-col">FREE <div className="price">₹0</div></div>
                            <div className="pt-col">STANDARD <div className="price">₹199</div></div>
                            <div className="pt-col">BUSINESS <div className="price">₹999</div></div>
                            <div className="pt-col">PROFESSIONAL <div className="price">₹1999</div></div>
                        </div>
                        <div className="pricing-table-row pricing-table-body">
                            <div className="pt-col"><span>Make Your Own Business Card</span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                        </div>
                        <div className="pricing-table-row pricing-table-body">
                            <div className="pt-col"><span>Choose Themes</span></div>
                            <div className="pt-col"><span>1 Free Theme</span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                        </div>
                        <div className="pricing-table-row pricing-table-body">
                            <div className="pt-col"><span>Publish for Public Use</span></div>
                            <div className="pt-col"><span>1 Card for 13 Days Trial</span></div>
                            <div className="pt-col"><span>1 Month</span></div>
                            <div className="pt-col"><span>1 Year</span></div>
                            <div className="pt-col"><span>Lifetime</span></div>
                        </div>
                        <div className="pricing-table-row pricing-table-body">
                            <div className="pt-col"><span>Access to See preview</span></div>
                            <div className="pt-col"><span>1 Card for 13 Days Trial</span></div>
                            <div className="pt-col"><span>1 Month</span></div>
                            <div className="pt-col"><span>1 Year</span></div>
                            <div className="pt-col"><span>Lifetime</span></div>
                        </div>
                        <div className="pricing-table-row pricing-table-footer">
                            <div className="pt-col"></div>
                            <div className="pt-col"><Link to="/create-card" className="primary-btn">Get Started</Link></div>
                            <div className="pt-col"><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></div>
                            <div className="pt-col"><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></div>
                            <div className="pt-col"><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></div>
                        </div>
                    </div>
                    <div className="pricing-table mobile-table">
                        <div className="row m-0">
                            <div className="col-md-4 col-6 p-0">
                                <div className="pricing-table-row pricing-table-body table-header-left">
                                    <div className="pt-col"></div>
                                    <div className="pt-col"><span>Make Your Own Business Card</span></div>
                                    <div className="pt-col"><span>Choose Themes</span></div>
                                    <div className="pt-col"><span>Publish for Public Use</span></div>
                                    <div className="pt-col"><span>Access to See preview</span></div>
                                    <div className="pt-col"></div>
                                </div>
                            </div>
                            <div className="col-md-8 col-6 p-0">
                                <Swiper navigation={true} modules={[Navigation]} className="mySwiper" breakpoints={
                                    {
                                        0: {
                                            slidesPerView: 1
                                        },
                                        767: {
                                            slidesPerView: 2
                                        }
                                    }
                                }>
                                    <SwiperSlide>
                                        <div className="pricing-table-row pricing-table-body">
                                            <div className="pt-col">FREE <div className="price">₹0</div></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span>1 Free Theme</span></div>
                                            <div className="pt-col"><span>1 Card for 13 Days Trial</span></div>
                                            <div className="pt-col"><span>1 Card for 13 Days Trial</span></div>
                                            <div className="pt-col"><span><Link to="/create-card" className="primary-btn">Get Started</Link></span></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="pricing-table-row pricing-table-body">
                                            <div className="pt-col">STANDARD <div className="price">₹199</div></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span>1 Month</span></div>
                                            <div className="pt-col"><span>1 Month</span></div>
                                            <div className="pt-col"><span><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></span></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="pricing-table-row pricing-table-body">
                                            <div className="pt-col">BUSINESS <div className="price">₹999</div></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span>1 Year</span></div>
                                            <div className="pt-col"><span>1 Year</span></div>
                                            <div className="pt-col"><span><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></span></div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="pricing-table-row pricing-table-body">
                                            <div className="pt-col">PROFESSIONAL <div className="price">₹1999</div></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span><i className='bx bx-check'></i></span></div>
                                            <div className="pt-col"><span>Lifetime</span></div>
                                            <div className="pt-col"><span>Lifetime</span></div>
                                            <div className="pt-col"><span><button className="primary-btn" onClick={() => handleSidecartToogle(true)}>Get Started</button></span></div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SideCart data={data} show={show} toogle={handleSidecartToogle}/>
        </Layout>
    )
}

export default Pricing;