import { useEffect } from "react"
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
const ValidateCookie = () => {
    useEffect(() => {
        const checkCookieValidity = () => {
            const userCookie = Cookies.get('user');
            const userLocalStorage = localStorage.getItem('user');
            if (!userCookie || userCookie !== userLocalStorage) {
              // toast.warning("Session Expired!");
              localStorage.removeItem('user');
            }
          };
          
          checkCookieValidity();
    }, []);

    return null;
}

export default ValidateCookie;