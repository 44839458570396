import { useEffect } from "react";
import DigitalCardLayout from "../components/DigitalCardLayout"

const CorporateBlueStatic = () => {
    useEffect(() => {
        import("./corporateblue.css");
    })
    return (
        <DigitalCardLayout>
<div class="box-divider" id="basic">
    <div class="card-wrapper shadow bg-white">
        <div class="card-head">
            <div class="card-head-logo">
                <img src="/assets/images/coollogo.png" class="company-logo" alt="logo" />
            </div>
        </div>
        <div class="profile">
            <div class="profile-picture mb-3">
                <img src="/assets/images/johndoe.jpg" alt="profilepic" />
            </div>
            <div class="text-center">
                <h1 class="h3 profile_name mb-0">John Doe</h1>
                <p class="text-muted m-0">CEO</p>
                <p class="company-name">John Associates</p>
            </div>
            <div class="row align-items-center social_box">
                <div class="col-2">
                    <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                </div>
                <div class="col-2">
                    <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                </div>
                <div class="col-2">
                    <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                </div>
                <div class="col-2">
                    <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                </div>
                <div class="col-2">
                    <a href="#" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                </div>
            </div>
            <ul class="contact_box">
                <li>
                    <div class="contact_icon">
                        <i class='bx bxs-phone'></i>
                    </div>
                    <div class="contact_content">
                        <a href="tel:#">+91 9830565580</a>
                    </div>
                </li>
                <li>
                    <div class="contact_icon">
                        <i class='bx bxs-envelope'></i>
                    </div>
                    <div class="contact_content">
                        <a href="mailto:#">johndoe@example.com</a>
                    </div>
                </li>
                <li>
                    <div class="contact_icon">
                        <i class='bx bx-globe'></i>
                    </div>
                    <div class="contact_content">
                        <a href="#" target="_blank">example.com</a>
                    </div>
                </li>
                <li>
                    <div class="contact_icon">
                        <i class='bx bxs-map'></i>
                    </div>
                    <div class="contact_content">
                        <a href="#" target="_blank">
                            33, Townshend Road, Purnashish Building, Ground Floor,
                            Kolkata -700025 West Bengal, India
                        </a>
                    </div>
                </li>
            </ul>
            {/* <div class="share_box">
                <div class="share_form">
                    <input type="text" value="+91 " id="wpnum"/>
                    <button type="button" id="sharewhatapp"><i class='bx bxl-whatsapp'></i> Share On WhatsApp</button>
                </div>
                <div class="row share_btns">
                    <div class="col-6">
                        <button class="btn btn-dark" id="savetocontact">Save to Contacts <i class='bx bx-download'></i></button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#sharecardModal">Share <i class='bx bx-share-alt'></i></button>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</div>
<div class="box-divider" id="about">
    <div class="card-wrapper bg-white shadow">
        <div class="about-content">
            <h2 class="h3 text-center bdr_btm">About Us</h2>
            <p>John Doe is a pseudonym used to represent an anonymous or hypothetical individual, often in legal contexts, where the true identity is either unknown or intentionally withheld. This generic name has become emblematic of anonymity in various fields, from legal proceedings to literature and film. John Doe serves as a placeholder, embodying the everyman or the common person, devoid of specific characteristics or identity markers.</p>
            <p>In legal contexts, John Doe is frequently employed when referring to unidentified parties in lawsuits or criminal cases. For instance, a plaintiff might file a lawsuit against "John Doe" if they are unaware of the defendant's true identity but wish to proceed with legal action. Similarly, law enforcement might issue a warrant for the arrest of a suspect known only as John Doe until their identity is determined.</p>
            <p>Outside of the legal realm, the name John Doe has permeated popular culture, appearing in various works of fiction, such as novels, films, and television shows. In these contexts, John Doe often represents an enigmatic figure, shrouded in mystery or operating under an assumed identity. This archetype has been explored in numerous genres, including crime thrillers, psychological dramas, and science fiction, adding to its enduring appeal and cultural significance.</p>
            <p>Beyond its use in formal and creative contexts, John Doe has also found its way into everyday language as a symbol of anonymity and universality. When individuals wish to convey a sense of anonymity or represent the average person, they might use the term "John Doe" or its female counterpart, "Jane Doe." This widespread usage underscores the enduring relevance of John Doe as a symbol of anonymity and the universal human experience.</p>
        </div>
    </div>
</div>
<div class="box-divider" id="shop">
    <div class="card-wrapper bg-white shadow">
        <div class="shop_online">
            <h2 class="h3 text-center bdr_btm">Shop Online</h2>
            <div class="row">
                <div class="col-6">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                            <span class="text-muted">₹ 885</span>
                        </div>
                        <button class="btn btn-primary w-100">Buy</button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                            <span class="text-muted">₹ 885</span>
                        </div>
                        <button class="btn btn-primary w-100">Buy</button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                            <span class="text-muted">₹ 885</span>
                        </div>
                        <button class="btn btn-primary w-100">Buy</button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                            <span class="text-muted">₹ 885</span>
                        </div>
                        <button class="btn btn-primary w-100">Buy</button>
                    </div>
                </div>
                <div class="col-6">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                            <span class="text-muted">₹ 885</span>
                        </div>
                        <button class="btn btn-primary w-100">Buy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="products">
    <div class="card-wrapper bg-white shadow">
        <div class="shop_online products_enquiry">
            <h2 class="h3 text-center bdr_btm">Products & Services</h2>
            <div class="row">
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                        </div>
                        <button class="btn btn-primary w-100">Enquiry</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                        </div>
                        <button class="btn btn-primary w-100">Enquiry</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                        </div>
                        <button class="btn btn-primary w-100">Enquiry</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                        </div>
                        <button class="btn btn-primary w-100">Enquiry</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                        <div class="product-content text-center">
                            <h3 class="h6">DSC</h3>
                        </div>
                        <button class="btn btn-primary w-100">Enquiry</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="gallery">
    <div class="card-wrapper bg-white shadow">
        <div class="shop_online products_enquiry">
            <h2 class="h3 text-center bdr_btm">Gallery</h2>
            <div class="row">
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="product_box">
                        <div class="product-image">
                            <img src="~/assets-new/img/product/02.jpg" alt="product" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="box-divider" id="payment">
    <div class="card-wrapper bg-white shadow">
        <div class="bakdetails p-20">
            <h2 class="h3 text-center bdr_btm">Payment Info</h2>
            <div class="h6">Bank Account Details:-</div>
            <div class="table-responsive">
                <table class="table table-striped table-hover bankdetails">
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>:</td>
                            <td>JOHN ASSOCIATES</td>
                        </tr>
                        <tr>
                            <td>Account No.</td>
                            <td>:</td>
                            <td>1812897529</td>
                        </tr>
                        <tr>
                            <td>IFSC Code</td>
                            <td>:</td>
                            <td>KKBK0001383</td>
                        </tr>
                        <tr>
                            <td>Bank Name</td>
                            <td>:</td>
                            <td>Kotak Mahindra Bank</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="qr-code">
                <img src="~/assets-new/img/product/qr.webp" alt="qr" />
            </div>
        </div>
    </div>
</div>
{/* <div class="box-divider" id="feedback">
    <div class="card-wrapper bg-white shadow">
        <div class="contact p-20">
            <h2 class="h3 text-center bdr_btm">Feedback</h2>
            <p class="mb-0">Select Star</p>
            <div class="containet d-flex">
                <div class="rating">
                    <input type="radio" name="clr" style="--c: #ff9933" />
                    <input type="radio" name="clr" style="--c: #ff9933" />
                    <input type="radio" name="clr" style="--c: #ff9933" />
                    <input type="radio" name="clr" style="--c: #ff9933" />
                    <input type="radio" name="clr" style="--c: #ff9933" />
                </div>
            </div>
            <div class="form-group mt-4 mb-3">
                <div class="mb-2">
                    <input type="text" id="yourname" placeholder="Your Name" class="form-control" />
                </div>
                <div class="mb-2">
                    <input type="email" id="emailid" placeholder="Email ID" class="form-control" />
                </div>
                <div class="mb-2">
                    <input type="tel" id="phonenumber" placeholder="Your Contact" class="form-control" />
                </div>
                <div class="mb-2">
                    <textarea id="feedback" placeholder="Your Feedback" class="form-control"></textarea>
                </div>
                <button class="btn btn-primary w-100">Submit</button>
            </div>
            <p class="fsize-14">Note: for privecy and security reasons we do not show your contact details. For more info you can contact admin or your franchisee.</p>
            <h6 class="text-primary mb-4">Latest feedback</h6>
            <div class="feedbackbox">
                <ul class="feeback-rating">
                    <li><i class="fa-solid fa-star"></i></li>
                    <li><i class="fa-solid fa-star"></i></li>
                    <li><i class="fa-solid fa-star"></i></li>
                    <li><i class="fa-solid fa-star"></i></li>
                    <li><i class="fa-solid fa-star"></i></li>
                </ul>
                <p class="text-muted fsize-14">5/5 Rating</p>
                <p><strong>Good</strong></p>
                <p class="fsize-14"><i>By: K.Sundara Murthy</i></p>
                <p class="fsize-14 mb-0 text-right">Date: 27/Apr/2024 06:48PM</p>
            </div>
        </div>
    </div>
</div> */}
<div class="box-divider" id="contact">
    <div class="card-wrapper bg-white shadow pb-3">
        <div class="contact p-20">
            <h2 class="h3 text-center bdr_btm">Contact</h2>
            <div class="form-group mb-3">
                <div class="mb-2">
                    <input type="text" id="yourname_CNT" placeholder="Your Name" class="form-control" />
                </div>
                <div class="mb-2">
                    <input type="email" id="emailid_CNT" placeholder="Email ID" class="form-control" />
                </div>
                <div class="mb-2">
                    <input type="tel" id="phonenumber_CNT" placeholder="Your Contact" class="form-control" />
                </div>
                <div class="mb-2">
                    <textarea id="feedback_CNT" placeholder="Your Message" class="form-control"></textarea>
                </div>
                <button class="btn btn-primary w-100">Send!</button>
            </div>
        </div>
        <footer class="footer">
            <a href="#" class="fsize-14">emastersindia.net || Make Your Own Card Now || 2024</a>
        </footer>
    </div>
</div>
        </DigitalCardLayout>
    )
}

export default CorporateBlueStatic;